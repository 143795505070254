import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import JourneyPage from "./Pages/JourneyPage/JourneyPage";
import CruisePage from "./Pages/CruisePage/CruisePage";
import RoadTripPage from "./Pages/RoadTripPage/RoadTripPage";
import Legal from "./Components/legal/legal";
import CruiseJourneyPage from "./Pages/CruisePage/CruiseJourneyPage";
import CruiseUploadPage from "./Pages/Admin/CruiseUpload";
import DreamListPage from "./Pages/DreamListPage/DreamListPage";
import ItemPage from "./Pages/ItemPage/itemPage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { auth, db } from "./firebase";
import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import UserContext from "./UserContext";

function App() {
  const [userDetails, setUserDetails] = useState(null);
  const [journeys, setJourneys] = useState([]);
  const [dreamLists, setDreamLists] = useState([]);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          console.log("User is signed in:", firebaseUser); // Log when a user is signed in
          // User is signed in, so we get the user details from Firestore.
          const userDoc = doc(db, "Users", firebaseUser.uid);
          const userDocSnapshot = await getDoc(userDoc);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();

            // Fetch journeys and dream lists
            const fetchUserData = async () => {
              const journeys = userData.journeys || [];

              // Fetch DreamLists using the IDs
              const dreamListIds = userData.dreamLists || [];
              let dreamLists = [];
              if (dreamListIds.length > 0) {
                const q = query(
                  collection(db, "DreamLists"),
                  where("id", "in", dreamListIds)
                );
                const querySnapshot = await getDocs(q);
                dreamLists = querySnapshot.docs.map((doc) => doc.data());
              }

              setUserDetails({
                ...userData,
                journeys,
                dreamLists,
              });
              setJourneys(journeys);
              setDreamLists(dreamLists);
            };

            fetchUserData();
          } else {
            // Handle the case where the user document does not exist.
            console.error(
              `No document found for user with ID: ${firebaseUser.uid}`
            );
          }
        } else {
          console.log("No user is signed in."); // Log when no user is signed in
          // User is signed out.
          setUserDetails(null);
          setJourneys([]);
          setDreamLists([]);
        }
      }
    );

    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const updateUserDetails = async () => {
    if (auth.currentUser) {
      const userDoc = doc(db, "Users", auth.currentUser.uid);
      const userDocSnapshot = await getDoc(userDoc);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        // Fetch journeys and dream lists
        const fetchUserData = async () => {
          const journeys = userData.journeys || [];

          // Fetch DreamLists using the IDs
          const dreamListIds = userData.dreamLists || [];
          let dreamLists = [];
          if (dreamListIds.length > 0) {
            const q = query(
              collection(db, "DreamLists"),
              where("id", "in", dreamListIds)
            );
            const querySnapshot = await getDocs(q);
            dreamLists = querySnapshot.docs.map((doc) => doc.data());
          }

          setUserDetails({
            ...userData,
            journeys,
            dreamLists,
          });
          setJourneys(journeys);
          setDreamLists(dreamLists);
        };

        fetchUserData();
      } else {
        console.error(
          `No document found for user with ID: ${auth.currentUser.uid}`
        );
      }
    }
  };

  useEffect(() => {
    console.log(userDetails);
  }, [userDetails]); // This useEffect will run every time `userDetails` changes.

  return (
    <UserContext.Provider value={userDetails}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/journey/:id" element={<JourneyPage />} />
          <Route path="/cruise" element={<CruisePage />} />
          <Route path="/cruise/:id" element={<CruiseJourneyPage />} />
          <Route path="/roadtrip" element={<RoadTripPage />} />
          <Route path="/dreamlist/:id" element={<DreamListPage />} />
          <Route path="/:id" element={<ItemPage />} />
          <Route path="/admin/cruise-upload" element={<CruiseUploadPage />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
