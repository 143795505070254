import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firestore = getFirestore(); // Initialize Firestore

const EditItinerary = ({ item, onSave, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [days, setDays] = useState(item.itinerary.days);
  const [selectedDate, setSelectedDate] = useState(null);

  console.log("days", days);

  const handleTypeChange = (index, value) => {
    const newDays = [...days];
    newDays[index].type = value;
    setDays(newDays);
  };

  const addDay = () => {
    const newDay = {
      day: days.length + 1,
      date: selectedDate ? selectedDate.toISOString().split("T")[0] : "",
      type: "",
      placeId: "",
      lat: "",
      lng: "",
      location: "",
      name: "",
      destinationId: "",
      imageUrl: "",
      address: "",
      city: "",
      state: "",
      country: "",
      countryCode: "",
      geopoint: { latitude: "", longitude: "" },
      itemType: "",
    };
    setDays([...days, newDay]);
    setSelectedDate(null);
  };

  const handleDateChange = (index, newValue) => {
    const newDays = [...days];
    newDays[index].date = newValue;
    setDays(newDays);
  };

  const handleSelect = async (index, address) => {
    try {
      console.log(`Geocoding address: ${address}`);
      const results = await geocodeByAddress(address);
      console.log("Geocode results:", results);

      const latLng = await getLatLng(results[0]);
      console.log("LatLng:", latLng);

      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      const destinationData = {
        name: address,
        placeId,
        address,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "N/A",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "N/A",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "N/A",
        countryCode:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.short_name || "N/A",
        geopoint: {
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
        itemType: "Destination",
      };

      console.log("Destination data:", destinationData);

      // Update the specific day with all the destination data
      const newDays = [...days];
      newDays[index] = {
        ...newDays[index],
        ...destinationData,
        location: address,
      };
      setDays(newDays);
      console.log("Updated days after handleSelect:", newDays);

      // Query the Destinations collection for a destination with the same name
      const q = query(
        collection(firestore, "Destinations"),
        where("name", "==", destinationData.name)
      );
      const querySnapshot = await getDocs(q);

      let destinationId;

      if (querySnapshot.empty) {
        console.log(
          "Destination does not exist in the Destinations collection"
        );

        let blob;

        // Get the place ID from the destination data
        if (typeof placeId === "string") {
          const functionUrl = `https://australia-southeast1-kaleido-travel-vlogs.cloudfunctions.net/getImage?placeId=${encodeURIComponent(
            placeId
          )}`;
          const response = await fetch(functionUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const buffer = await response.arrayBuffer();
          blob = new Blob([buffer], { type: "image/jpeg" });
        }

        const storageRef = ref(
          getStorage(),
          `destinationImages/${destinationData.name}`
        );
        const uploadSnapshot = await uploadBytes(storageRef, blob);
        const url = await getDownloadURL(storageRef);
        destinationData.imageUrl = url;

        const docRef = await addDoc(
          collection(firestore, "Destinations"),
          destinationData
        );
        destinationId = docRef.id;
      } else {
        // If the destination does exist, use its data
        const doc = querySnapshot.docs[0];
        const existingDestinationData = doc.data();
        destinationId = doc.id;
        destinationData.imageUrl = existingDestinationData.imageUrl;
      }

      newDays[index] = {
        ...newDays[index],
        ...destinationData,
        destinationId,
        lat: latLng.lat,
        lng: latLng.lng,
        placeId: placeId,
      };
      setDays(newDays);
      console.log("New days:", newDays);
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      console.log("days", days);

      const cruiseJourneyRef = doc(firestore, "CruiseJourneys", item.id);
      await updateDoc(cruiseJourneyRef, {
        itinerary: {
          days: days,
          createdAt: serverTimestamp(),
        },
      });
      console.log("days", days);
      onClose();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <form
          onSubmit={handleSubmit((data) => {
            console.log("Form submitted");
            onSubmit(data);
          })}
        >
          <Grid container direction="column" spacing={2}>
            {days.map((day, index) => (
              <Grid item key={index}>
                <InputLabel>Day {day.day}</InputLabel>
                <Controller
                  name={`days[${index}].date`}
                  control={control}
                  defaultValue={day.date}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      label="Select Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={day.date || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleDateChange(index, e.target.value);
                      }}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.date?.message}
                </FormHelperText>
                <Controller
                  name={`days[${index}].type`}
                  control={control}
                  defaultValue={day.type}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Day Type</em>;
                        }
                        return selected;
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        handleTypeChange(index, e.target.value);
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Day Type</em>
                      </MenuItem>
                      <MenuItem value="Sea Day">Sea Day</MenuItem>
                      <MenuItem value="destination">Port Day</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.type?.message}
                </FormHelperText>
                {day.type === "destination" && (
                  <>
                    <PlacesAutocomplete
                      value={day.location}
                      onChange={(address) => {
                        const newDays = [...days];
                        newDays[index].location = address;
                        setDays(newDays);
                      }}
                      onSelect={(address) => handleSelect(index, address)}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              label: "Location",
                              fullWidth: true,
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <FormHelperText error>
                      {errors.days && errors.days[index]?.location?.message}
                    </FormHelperText>
                  </>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={addDay}
              style={{ margin: "15px" }}
            >
              + Add Day
            </Button>
          </Grid>
          <Grid item>
            <div className="formButtons">
              <button
                className="submitButton"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save Itinerary
              </button>
              <button className="cancelButton" type="button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default EditItinerary;
