/* global */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./newItem.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const firestore = getFirestore(); // Initialize Firestore

function NewCruiseItinerary(props) {
  const { onClose } = props;
  const { item } = props;
  const [days, setDays] = useState([
    {
      day: 1,
      date: "",
      type: "sea",
      location: "",
      placeId: "",
      lat: "",
      lng: "",
    },
  ]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ name: "", videoLink: "" }],
      // other form fields...
    },
  });

  const addDay = () => {
    setDays([
      ...days,
      {
        day: days.length + 1,
        date: "",
        type: "sea",
        location: "",
        placeId: "",
        lat: "",
        lng: "",
      },
    ]);
  };

  const handleTypeChange = (index, type) => {
    const newDays = [...days];
    newDays[index].type = type;
    setDays(newDays);
  };

  const handleSelect = async (index, address) => {
    const newDays = [...days];
    newDays[index].location = address;
    setDays(newDays);

    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      const destinationData = {
        name: address,
        placeId,
        address,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "N/A",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "N/A",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "N/A",
        countryCode:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.short_name || "N/A",
        geopoint: {
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
        itemType: "Destination",
      };

      // Query the Destinations collection for a destination with the same name
      const q = query(
        collection(firestore, "Destinations"),
        where("name", "==", destinationData.name)
      );
      const querySnapshot = await getDocs(q);

      let destinationId;

      if (querySnapshot.empty) {
        console.log(
          "Destination does not exist in the Destinations collection"
        );

        let blob;

        // Get the place ID from the destination data
        if (typeof placeId === "string") {
          const functionUrl = `https://australia-southeast1-kaleido-travel-vlogs.cloudfunctions.net/getImage?placeId=${encodeURIComponent(
            placeId
          )}`;
          const response = await fetch(functionUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const buffer = await response.arrayBuffer();
          blob = new Blob([buffer], { type: "image/jpeg" });
        } else {
          console.error("placeId is not a string or is undefined:", placeId);
        }

        if (blob) {
          // Upload the image to Firebase Storage
          const storage = getStorage();
          const storageRef = ref(
            storage,
            `destinationImages/${destinationData.name}`
          );
          const uploadSnapshot = await uploadBytes(storageRef, blob);
          console.log("Upload snapshot:", uploadSnapshot);

          // Get the download URL for the image
          const url = await getDownloadURL(storageRef);
          console.log("Download URL:", url);

          // Store the download URL in the destinationData
          destinationData.imageUrl = url;

          // Add the destination to the Destinations collection
          const docRef = await addDoc(
            collection(firestore, "Destinations"),
            destinationData
          );
          console.log("Added new destination with ID: ", docRef.id);
          // Assign the new destination's ID to destinationId
          destinationId = docRef.id;
        } else {
          console.log("Blob is not defined");
        }
      } else {
        // If the destination does exist, use its data
        console.log("Found existing destination with data: ", destinationData);

        // Get the first document from the querySnapshot
        const doc = querySnapshot.docs[0];

        // Get the data from the document
        const existingDestinationData = doc.data();

        // Get the ID of the destination document
        destinationId = doc.id;

        // Use the existing destination's imageUrl
        destinationData.imageUrl = existingDestinationData.imageUrl;
      }

      newDays[index] = {
        ...newDays[index],
        ...destinationData,
        destinationId,
        lat: latLng.lat,
        lng: latLng.lng,
        placeId: placeId,
      };
      setDays(newDays);
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const onSubmit = async (data) => {
    const firestore = getFirestore(); // Initialize Firestore
    try {
      const cruiseJourneyRef = doc(firestore, "CruiseJourneys", item.id);
      await updateDoc(cruiseJourneyRef, {
        itinerary: {
          ...data,
          days,
          createdAt: serverTimestamp(),
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Itinerary updated successfully.",
        customClass: {
          container: "swal",
        },
      }).then(() => {
        window.location.reload(); // Refresh the page
      });
      onClose();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: "20px" }}>
          <div className="formHeading">
            <h1 className="headingdark">Add a new </h1>
            <h1 className="headingLight">Itinerary</h1>
          </div>
          <Grid
            className="formControl"
            container
            direction="column"
            spacing={2}
          >
            {days.map((day, index) => (
              <Grid item key={index}>
                <InputLabel className="inputLabel">Day {day.day}</InputLabel>
                <Controller
                  name={`days[${index}].date`}
                  control={control}
                  defaultValue={day.date}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        const newDays = [...days];
                        newDays[index].date = e.target.value;
                        setDays(newDays);
                      }}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.date?.message}
                </FormHelperText>
                <Controller
                  name={`days[${index}].type`}
                  control={control}
                  defaultValue={day.type}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      displayEmpty
                      onChange={(e) => {
                        field.onChange(e);
                        handleTypeChange(index, e.target.value);
                      }}
                      label="Type"
                    >
                      <MenuItem value="" disabled>
                        Select Day Type
                      </MenuItem>
                      <MenuItem value="Sea Day">Sea Day</MenuItem>
                      <MenuItem value="destination">Port Day</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.type?.message}
                </FormHelperText>
                {day.type === "destination" && (
                  <>
                    <PlacesAutocomplete
                      value={day.location}
                      onChange={(address) => {
                        const newDays = [...days];
                        newDays[index].location = address;
                        setDays(newDays);
                      }}
                      onSelect={(address) => handleSelect(index, address)}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              label: "Location",
                              fullWidth: true,
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <FormHelperText error>
                      {errors.days && errors.days[index]?.location?.message}
                    </FormHelperText>
                  </>
                )}
              </Grid>
            ))}
            <Grid item>
              <Button variant="contained" onClick={addDay}>
                + Add Day
              </Button>
            </Grid>
            <hr />
            <Grid item>
              <div className="formButtons">
                <button className="submitButton" type="submit">
                  Submit
                </button>
                <button
                  className="cancelButton"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default NewCruiseItinerary;
