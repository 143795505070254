import React from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

export default class Flip extends React.Component {
  constructor(props) {
    super(props);
    this._tickRef = React.createRef();
  }

  componentDidMount() {
    this._tickInstance = Tick.DOM.create(this._tickRef.current, {
      value: this.props.value,
    });

    // Notify parent component that Flip is fully rendered
    if (this.props.onRendered) {
      this.props.onRendered();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this._tickInstance) return;
    if (prevProps.value !== this.props.value) {
      this._tickInstance.value = this.props.value;

      // Notify parent component that Flip is fully rendered
      if (this.props.onRendered) {
        this.props.onRendered();
      }
    }
  }

  componentWillUnmount() {
    if (!this._tickInstance) return;
    Tick.DOM.destroy(this._tickRef.current);
    this._tickInstance = null; // Ensure the instance is set to null
  }

  render() {
    return (
      <div ref={this._tickRef} className="tick" style={this.props.style}>
        <div data-repeat="true" aria-hidden="true" data-layout="horizontal">
          <span data-view="flip">Tick</span>
        </div>
      </div>
    );
  }
}