import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import MapIcon from "@mui/icons-material/Map";
import ShareIcon from "@mui/icons-material/Share";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { fromUnixTime, format } from "date-fns";
import "./item.css";

const JourneyItemCategory = ({ item, userId, onItemUpdate }) => {

  function formatLocation(item) {
    let location = item.country;

    if (item.state && item.state !== "N/A") {
      location = `${item.state}, ${location}`;
    }

    if (item.city && item.city !== "N/A") {
      location = `${item.city}, ${location}`;
    }

    return location;
  }

  console.log(item);

  return (
    <>
      <div className="feedItem">
        <Paper elevation={24} className="hand-drawn-rectangle">
          <div className="topHalf">
            <div className="itemInfo">
              <MapIcon className="itemIcon" />
              <h2 className="itemHeader">{item.category}</h2>
              <ReactCountryFlag
                countryCode={item.countryCode}
                svg
                style={{
                  width: "10%",
                  height: "10%",
                  marginTop: "10px",
                  border: "1px solid #1bb6ca",
                  borderRadius: "5px",
                }}
                title={item.country}
              />
            </div>
            <div className="itemTop">
              <h2 className="itemName">{item.journeyName}</h2>
              <p className="journeyItemLocaion"> in {formatLocation(item)}</p>
              {item.startDate && (
                <p className="itemDate">
                  {item.startDate && (
                    <p className="itemDate">
                      {format(
                        fromUnixTime(item.startDate / 1000),
                        "do 'of' MMMM yyyy"
                      )}
                      {item.endDate &&
                        ` to ${format(
                          fromUnixTime(item.endDate / 1000),
                          "do 'of' MMMM yyyy"
                        )}`}
                    </p>
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="itemBody">
            {item.aboutJourney && (
              <p className="itemAbout">{item.aboutJourney}</p>
            )}
          </div>
          <div className="ratings">
            <Link to={`/journey/${item.objectID}`}>
              <Chip
                label="Click here to view the full Journey"
                className="userChip"
                sx={{
                  color: "white",
                  backgroundColor: "#1bb6ca",
                  fontSize: "1rem",
                  zIndex: "0",
                }}
              />
            </Link>
          </div>
          <hr />
          <h2 className="itemName">Travellers:</h2>
          <div className="itemTravellers">
            <Chip
              avatar={<PersonIcon style={{ color: "#415063" }} />}
              label={item.userName}
              style={{
                margin: "0.5em",
                backgroundColor: "#1bb6ca",
                color: "#FFF",
              }}
            />
          </div>
          {item.travellers && item.travellers.length > 0 && (
            <div className="itemTravellers">
              {item.travellers.map((traveller, index) => (
                <Chip
                  avatar={<PersonIcon style={{ color: "#415063" }} />}
                  label={traveller.userName}
                  style={{
                    margin: "0.5em",
                    backgroundColor: "#1bb6ca",
                    color: "#FFF",
                  }}
                />
              ))}
            </div>
          )}
        </Paper>
        <div className="itemActions">
          <div className="itemButtons">
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyItemCategory;
