import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Box,
  useMediaQuery,
  Chip,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.svg";
import tiktok from "../../Images/tiktok.svg";
import x from "../../Images/x.png";
import youtube from "../../Images/youtube.png";
import { Link } from "react-router-dom";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";

const CheckInCard = ({ checkInData }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Card
        sx={{
          margin: 2,
          borderRadius: 10,
          boxShadow: 3,
          border: "5px solid #1bb6ca",
          color: "#415063",
          width: isMobile ? "77%" : "30%",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom>
                {checkInData.userName} has checked in at:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="div" gutterBottom>
                <LocationOnIcon
                  sx={{ verticalAlign: "middle", color: "#1bb6ca" }}
                />{" "}
                {checkInData.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                <AccessTimeIcon
                  sx={{ verticalAlign: "middle", color: "#1bb6ca" }}
                />{" "}
                {checkInData.createdAt instanceof Timestamp
                  ? checkInData.createdAt.toDate().toLocaleString()
                  : new Date(checkInData.createdAt).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {checkInData.countryCode && (
                <>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #1bb6ca",
                      marginRight: "5px",
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={checkInData.countryCode}
                      svg
                      style={{
                        width: "3em",
                        height: "auto",
                      }}
                      title={checkInData.country}
                    />
                  </div>
                  <Typography variant="body2" color="text.secondary">
                    {checkInData.address}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                {checkInData.locationType}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ fontStyle: "italic" }}
              >
                {checkInData.note}
              </Typography>
            </Grid>
            {checkInData.journey && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Part of the journey:{" "}
                  <Link
                    to={`/journey/${checkInData.journey.id}`}
                    style={{ color: "#1bb6ca" }}
                  >
                    {checkInData.journey.name}
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CheckInCard;
