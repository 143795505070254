import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ExploreIcon from "@mui/icons-material/Explore";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from "@mui/icons-material/Comment";
import Avatar from "@mui/material/Avatar";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import Experience from "../experience/experience";
import { query, where } from "firebase/firestore";
import UserContext from "../../UserContext";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ReactCountryFlag from "react-country-flag";
import LinearProgress from "@mui/material/LinearProgress";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.svg";
import tiktok from "../../Images/tiktok.svg";
import x from "../../Images/x.png";
import youtube from "../../Images/youtube.png";
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { InputLabel } from "@mui/material";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  arrayUnion,
  arrayRemove,
  getFirestore,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { formatDistanceToNow } from "date-fns";
import { formatDistance } from "date-fns";
import "./item.css";

const UserItem = ({ item, userId, onItemUpdate }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [userData, setUserData] = useState(null);
  const [liked, setLiked] = useState(
    item && item.likes ? item.likes.includes(userId) : false
  );
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);

  console.log("UserItem", item);

  return (
    <>
      <div className="feedItem">
        <Paper elevation={24} className="hand-drawn-rectangle">
          <div className="topHalf">
            <div className="itemInfoSingle">
              <PersonIcon className="itemIcon" />
              <h2 className="itemHeader">{item.category}</h2>
            </div>
            <div className="itemTop">
              <h2 className="itemName">{item.userName}</h2>
              <address className="itemAddress">{item.aboutUser}</address>
            </div>
            <div>
              <div>
                {item.facebook && (
                  <IconButton href={item.facebook} target="_blank">
                    <img src={facebook} alt="Facebook" className="iconImage" />
                  </IconButton>
                )}
                {item.instagram && (
                  <IconButton href={item.instagram} target="_blank">
                    <img
                      src={instagram}
                      alt="Instagram"
                      className="iconImage"
                    />
                  </IconButton>
                )}
                {item.tiktok && (
                  <IconButton href={item.tiktok} target="_blank">
                    <img src={tiktok} alt="TikTok" className="iconImage" />
                  </IconButton>
                )}
                {item.x && (
                  <IconButton href={item.x} target="_blank">
                    <img src={x} alt="X" className="iconImage" />
                  </IconButton>
                )}
                {item.youtube && (
                  <IconButton href={item.youtube} target="_blank">
                    <img src={youtube} alt="YouTube" className="iconImage" />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
          <div className="itemBody"></div>
          <hr />
          <div className="itemBody">
            <h2 className="itemHeader" style={{ marginRight: "10px", marginLeft: "10px" }}>
              {item.userName}'s Stats
            </h2>
            <h3 className="statRank">
              Rank: {item && item.stats && item.stats.rank}
            </h3>
            <div className="statSection">
              <Grid container spacing={3}>
                {item && item.stats && item.stats.Hotel && (
                  <Grid item xs={12} sm={6} md={4}>
                    <>
                      <h4>Hotel</h4>
                      <div>Rank: {Math.floor(item.stats.Hotel.rank)}</div>
                      Hotels stayed in:{" "}
                      {item &&
                        item.stats &&
                        item.stats.Hotel &&
                        item.stats.Hotel.totalSubmissions}
                      <br />
                      <div>
                        Hotels points:{" "}
                        {item &&
                          item.stats &&
                          item.stats.Hotel &&
                          item.stats.Hotel.points && (
                            <>{item.stats.Hotel.points} </>
                          )}{" "}
                        <br />
                        {item &&
                          item.stats &&
                          item.stats.Hotel &&
                          item.stats.Hotel.points && (
                            <>
                              <LinearProgress
                                variant="determinate"
                                value={(item.stats.Hotel.points % 50) * 2}
                              />
                            </>
                          )}
                      </div>
                      <br />
                    </>
                  </Grid>
                )}

                {item && item.stats && item.stats.Activity && (
                  <Grid item xs={12} sm={6} md={4}>
                    <>
                      <h4>Activity</h4>
                      <div>Rank: {Math.floor(item.stats.Activity.rank)}</div>
                      Activities:{" "}
                      {item &&
                        item.stats &&
                        item.stats.Activity &&
                        item.stats.Activity.totalSubmissions}
                      <br />
                      <div>
                        Activity points:{" "}
                        {item &&
                          item.stats &&
                          item.stats.Activity &&
                          item.stats.Activity.points && (
                            <>{item.stats.Activity.points} </>
                          )}{" "}
                        <br />
                        {item &&
                          item.stats &&
                          item.stats.Activity &&
                          item.stats.Activity.points && (
                            <>
                              <LinearProgress
                                variant="determinate"
                                value={(item.stats.Activity.points % 50) * 2}
                              />
                            </>
                          )}
                      </div>
                      <br />
                    </>
                  </Grid>
                )}

                {item && item.stats && item.stats.Destination && (
                  <Grid item xs={12} sm={6} md={4}>
                    <>
                      <h4>Destination</h4>
                      <div>Rank: {Math.floor(item.stats.Destination.rank)}</div>
                      Destinations:{" "}
                      {item &&
                        item.stats &&
                        item.stats.Destination &&
                        item.stats.Destination.totalSubmissions}
                      <br />
                      <div>
                        Destination points:{" "}
                        {item &&
                          item.stats &&
                          item.stats.Destination &&
                          item.stats.Destination.points && (
                            <>{item.stats.Destination.points} </>
                          )}{" "}
                        <br />
                        {item &&
                          item.stats &&
                          item.stats.Destination &&
                          item.stats.Destination.points && (
                            <>
                              <LinearProgress
                                variant="determinate"
                                value={(item.stats.Destination.points % 50) * 2}
                              />
                            </>
                          )}
                      </div>
                      <br />
                    </>
                  </Grid>
                )}

                {item && item.stats && item.stats.Food && (
                  <Grid item xs={12} sm={6} md={4}>
                    <>
                      <h4>Food</h4>
                      <div>Rank: {Math.floor(item.stats.Food.rank)}</div>
                      Food:{" "}
                      {item &&
                        item.stats &&
                        item.stats.Food &&
                        item.stats.Food.totalSubmissions}
                      <br />
                      <div>
                        Food points:{" "}
                        {item &&
                          item.stats &&
                          item.stats.Food &&
                          item.stats.Food.points && (
                            <>{item.stats.Food.points} </>
                          )}{" "}
                        <br />
                        {item &&
                          item.stats &&
                          item.stats.Food &&
                          item.stats.Food.points && (
                            <>
                              <LinearProgress
                                variant="determinate"
                                value={(item.stats.Food.points % 50) * 2}
                              />
                            </>
                          )}
                      </div>
                      <br />
                    </>
                  </Grid>
                )}

                {item && item.stats && item.stats.ThemePark && (
                  <Grid item xs={12} sm={6} md={4}>
                    <>
                      <h4>Theme Park</h4>
                      <div>Rank: {Math.floor(item.stats.ThemePark.rank)}</div>
                      Theme Park:{" "}
                      {item &&
                        item.stats &&
                        item.stats.ThemePark &&
                        item.stats.ThemePark.totalSubmissions}
                      <br />
                      <div>
                      Theme Park points:{" "}
                        {item &&
                          item.stats &&
                          item.stats.ThemePark &&
                          item.stats.ThemePark.points && (
                            <>{item.stats.ThemePark.points} </>
                          )}{" "}
                        <br />
                        {item &&
                          item.stats &&
                          item.stats.ThemePark &&
                          item.stats.ThemePark.points && (
                            <>
                              <LinearProgress
                                variant="determinate"
                                value={(item.stats.ThemePark.points % 50) * 2}
                              />
                            </>
                          )}
                      </div>
                      <br />
                    </>
                  </Grid>
                )}
              </Grid>

              <div>
                <br />
                <br />
                Countries visited: <br />
                {item &&
                  item.stats &&
                  item.stats.locations &&
                  Object.keys(item.stats.locations).map((country) => {
                    const countryCode =
                      item.stats.locations[country].countryCode;
                    return (
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #1bb6ca",
                          marginRight: "5px",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={countryCode}
                          svg
                          style={{
                            width: "3em",
                            height: "auto",
                          }}
                          title={country}
                        />
                      </div>
                    );
                  })}
                <br />
                {item.state &&
                  item.state !== "N/A" && // Check if the state is not "N/A"
                  userData &&
                  userData.stats &&
                  userData.stats.locations &&
                  userData.stats.locations[item.country] &&
                  userData.stats.locations[item.country].states &&
                  userData.stats.locations[item.country].states[item.state] && (
                    <>
                      {item.state} points:{" "}
                      <LinearProgress
                        variant="determinate"
                        value={
                          (userData.stats.locations[item.country].states[
                            item.state
                          ].points %
                            50) *
                          2
                        }
                      />
                      <div>
                        {Math.floor(
                          userData.stats.locations[item.country].states[
                            item.state
                          ].points / 50
                        )}{" "}
                        rank(s) achieved
                      </div>
                    </>
                  )}
                <br />
                {item.city &&
                  item.country !== item.city && // Check if the country and city are not the same
                  userData &&
                  userData.stats &&
                  userData.stats.locations &&
                  userData.stats.locations[item.country] &&
                  userData.stats.locations[item.country].states &&
                  userData.stats.locations[item.country].states[item.state] &&
                  userData.stats.locations[item.country].states[item.state]
                    .cities &&
                  userData.stats.locations[item.country].states[item.state]
                    .cities[item.city] && (
                    <>
                      {item.city} points:{" "}
                      <LinearProgress
                        variant="determinate"
                        value={
                          (userData.stats.locations[item.country].states[
                            item.state
                          ].cities[item.city].points %
                            50) *
                          2
                        }
                      />
                      <div>
                        {Math.floor(
                          userData.stats.locations[item.country].states[
                            item.state
                          ].cities[item.city].points / 50
                        )}{" "}
                        rank(s) achieved
                      </div>
                      <div>
                        Countries visited: <br />
                        {userData &&
                          userData.stats &&
                          userData.stats.locations &&
                          Object.keys(userData.stats.locations).map(
                            (country) => {
                              const countryCode =
                                userData.stats.locations[country].countryCode;
                              return (
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #1bb6ca",
                                    marginRight: "5px",
                                  }}
                                >
                                  <ReactCountryFlag
                                    countryCode={countryCode}
                                    svg
                                    style={{
                                      width: "3em",
                                      height: "auto",
                                    }}
                                    title={country}
                                  />
                                </div>
                              );
                            }
                          )}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </Paper>
        <div className="itemActions">
          <div className="itemButtons">
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserItem;
