/* global google */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, getDownloadURL } from "firebase/storage";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./newItem.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function NewCruiseItem(props) {
  const { user, onClose, setRefresh, item } = props;
  const [category, setCategory] = useState("");
  const [experiencePrompts, setExperiencePrompts] = useState([]);
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const [uploadMetadata, setUploadMetadata] = useState([]);
  const [files, setFiles] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ name: "", videoLink: "" }],
      // other form fields...
    },
  });

  const handleProcess = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    const filePath = `UserImages/${user.userName}/Cruise/${file.name}`;
    const fileRef = storageRef(storage, filePath);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressPercentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
        console.log("Upload is " + progressPercentage + "% done");
      },
      (err) => {
        error(err.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          load(downloadURL);
          console.log("File available at", downloadURL);
          setUploadMetadata((prevMetadata) => [
            ...prevMetadata,
            { name: file.name, url: downloadURL },
          ]);
        });
      }
    );
  };

  console.log(item);

  useEffect(() => {
    const fetchExperiencePrompts = async () => {
      const experiencePromptsCollection = collection(
        db,
        "Experiences",
        "jfHCIMDg5Uj2PCkWxBEd",
        "Cruises",
        "RCgbIbSZBebTIyV2uuLo",
        "ExperiencePrompts"
      );
      const experiencePromptsSnapshot = await getDocs(
        experiencePromptsCollection
      );
      const experiencePromptsData = experiencePromptsSnapshot.docs.map((doc) =>
        doc.data()
      );
      setExperiencePrompts(experiencePromptsData);
    };

    fetchExperiencePrompts();
  }, []);

  const createNewJourneyItem = async (journeyItem) => {
    setLoading(true);
    console.log(item);
    try {
      // Add a createdAt field with the current timestamp
      journeyItem.createdAt = serverTimestamp();

      // Convert the startDate and endDate strings to Timestamps
      if (journeyItem.startDate) {
        journeyItem.startDate = Timestamp.fromDate(
          new Date(journeyItem.startDate)
        );
      }

      // Add the user details to the journeyItem
      journeyItem.userId = user.uid;
      journeyItem.userName = user.userName;

      // Add the selected journey ID and name to the journeyItem if a journey was selected
      journeyItem.cruiseId = item.id;

      // Add the journeyItem to the JourneyItems collection
      const journeyItemDocRef = await addDoc(
        collection(db, "CruiseItems"),
        journeyItem
      );
      console.log("Document written with ID: ", journeyItemDocRef.id);

      // Update user stats
      const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id
      const userSnap = await getDoc(userRef);
      let userData = userSnap.data();

      // Initialize stats field if it doesn't exist
      if (!userData.stats) {
        userData.stats = {
          rank: 1, // Initialize rank
          points: 0, // Initialize overall points
        };
      } else {
        // Initialize rank field if it doesn't exist
        if (userData.stats.rank === undefined) {
          userData.stats.rank = 1; // Initialize rank
        }

        if (typeof userData.stats.points !== "number") {
          // If userData.stats.points exists but is not a number, reset it to 0
          userData.stats.points = 0;
        }
      }

      // Initialize category field within stats if it doesn't exist
      if (!userData.stats.CruiseJourney) {
        userData.stats.CruiseJourney = {
          totalSubmissions: 0,
          uniqueSubmissions: {},
          points: 0,
          rank: 1, // Initialize rank for category
        };
      }

      userData.stats.CruiseJourney.totalSubmissions += 1;
      userData.stats.CruiseJourney.points += 10; // Increment category points
      userData.stats.points += 10; // Increment overall points

      // Update rank based on points
      userData.stats.rank = Math.floor(userData.stats.points / 100) + 1;

      // Update category rank based on category points
      userData.stats.CruiseJourney.rank =
        Math.floor(userData.stats.CruiseJourney.points / 50) + 1;

      // Add unique submission
      if (
        !userData.stats.CruiseJourney.uniqueSubmissions[journeyItemDocRef.id]
      ) {
        userData.stats.CruiseJourney.uniqueSubmissions[journeyItemDocRef.id] = {
          category: journeyItem.category,
          cruiseline: item.cruise.name,
          cruiseship: item.ship.name,
          cruiseDate: item.departureDate,
          cruisePort: item.departurePort,
          id: item.id,
        };
      }

      // Update user document in Firestore
      await updateDoc(userRef, userData);

      // Return the new journey item
      return { id: journeyItemDocRef.id, ...journeyItem };
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error; // re-throw the error to stop execution
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validateVideoLink = (index) => (videoLink) => {
    const experiences = getValues("experiences");
    const name = experiences[index]?.name;

    console.log("Name:", name);
    console.log("Video Link:", videoLink);

    const youtubeRegex =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const instagramVideoRegex =
      /(http|https):\/\/(www\.)?instagram.com\/(p|reel|tv)\/[^\/?\n]+\/?(\?.*)?/;
    const tiktokRegex =
      /(http|https):\/\/(www\.|vt\.)?tiktok.com\/(@.+\/video\/.+|Z.+\/)/;

    if (name && !videoLink) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Video link is required if a title is provided",
        customClass: {
          container: "swal",
        },
      });
      return false;
    }

    if (
      videoLink &&
      !(
        youtubeRegex.test(videoLink) ||
        instagramVideoRegex.test(videoLink) ||
        tiktokRegex.test(videoLink)
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid YouTube, Instagram, or TikTok link",
        customClass: {
          container: "swal",
        },
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    console.log("Submitting Form");
    console.log(data);
    console.log(item);

    // Filter out experiences with empty name and videoLink
    const filteredExperiences = data.experiences.filter(
      (experience) =>
        experience.name.trim() !== "" || experience.videoLink.trim() !== ""
    );

    // Ensure all fields have valid values
    const dataToSend = {
      ...data,
      category: category,
      experiences: filteredExperiences.map((experience) => ({
        ...experience,
        name: experience.name || "", // Default to empty string if undefined
        videoLink: experience.videoLink || "", // Default to empty string if undefined
      })),
      photos: uploadMetadata,
    };

    if (!category) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Category is required!",
        customClass: {
          container: "swal",
        },
      });
      return;
    }

    try {
      console.log("Creating new cruise item...");
      const newCruiseItem = await createNewJourneyItem(dataToSend);
      console.log("New cruise item created:", newCruiseItem);

      // Add the new item to the cruise
      console.log("Adding new item to cruise...");

      // Get a reference to the cruise document
      const cruiseRef = doc(db, "CruiseJourneys", item.id);

      // Fetch the current cruise document
      const cruiseSnap = await getDoc(cruiseRef);
      let cruiseData = cruiseSnap.data();

      // If the cruise doesn't have an items array, create it
      if (!cruiseData.items) {
        cruiseData.items = [];
      }

      // Add the new item to the cruise's items array
      cruiseData.items.push({
        id: newCruiseItem.id,
        category: newCruiseItem.category,
        userName: user.userName,
        userId: user.uid,
      });

      console.log(cruiseData.items);

      // Update the cruise document with the new items array
      await updateDoc(cruiseRef, {
        items: cruiseData.items,
      });

      console.log("New item added to cruise");

      console.log("Fetching user stats...");
      const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id

      // Fetch updated user stats
      console.log("Fetching updated user stats...");
      const updatedUserSnap = await getDoc(userRef);
      let updatedUserData = updatedUserSnap.data();
      const updatedCategoryStats = updatedUserData.stats.CruiseJourney;

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `New cruise item created successfully! You now have ${updatedCategoryStats.totalSubmissions} total submissions and ${updatedCategoryStats.points} points in this category.`,
        customClass: {
          container: "swal",
        },
      });
      onClose();
      setRefresh(true);
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div className="form-container">
      <div className="Itemform">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: "20px" }}>
          <div className="formHeading">
            <h1 className="headingdark">Add a new </h1>
            <h1 className="headingLight">Experience</h1>
          </div>
          <Grid
            className="formControl"
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel id="category-label">Category</InputLabel>
                <Controller
                  name="category"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      labelId="category-label"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setCategory(e.target.value);
                      }}
                    >
                      {experiencePrompts.map((prompt, index) => (
                        <MenuItem key={index} value={prompt.name}>
                          {prompt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel">Date</InputLabel>
              <Controller
                name="startDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {errors.startDate && errors.startDate.message}
              </FormHelperText>
            </Grid>
            <Grid item>
              <hr />
              <h3>Add video link</h3>
              <p>(Optional - this can be added later)</p>
              {getValues("experiences").map((experience, index) => (
                <div key={index}>
                  <Typography
                    className="inputLabel"
                    style={{ marginTop: "10px" }}
                  >
                    Title
                  </Typography>
                  <FormControl>
                    <Controller
                      name={`experiences[${index}].name`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Title"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              `experiences[${index}].name`,
                              e.target.value
                            );
                          }}
                        />
                      )}
                    />
                    <Box mt={2}>
                      <Typography
                        className="inputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Video Link
                      </Typography>
                      <FormControl>
                        <Controller
                          name={`experiences[${index}].videoLink`}
                          control={control}
                          defaultValue=""
                          rules={{
                            validate: validateVideoLink(index),
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Video Link"
                              fullWidth
                            />
                          )}
                        />
                        <FormHelperText>
                          Enter the link to your YouTube, Instagram, or TikTok
                          video
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </FormControl>
                </div>
              ))}
            </Grid>
            <Grid item>
              <hr />
              <InputLabel className="inputLabel">
                Upload Photos (Optional - this can be added later)
              </InputLabel>
              <FormControl style={{ width: "100%" }}>
                <div style={{ width: "100%", border: "2px solid #1bb6ca" }}>
                  <FilePond
                    files={files}
                    allowMultiple={true}
                    maxFileSize="5MB" // Set the maximum file size to 5MB
                    acceptedFileTypes={["image/jpeg", "image/png"]} // Limit file types to JPEG and PNG
                    onprocessfile={(error, file) => {
                      console.log("File processed", file.serverId);
                    }}
                    server={{ process: handleProcess }}
                    onupdatefiles={setFiles}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    labelMaxFileSizeExceeded="File is too large" // Custom message for files that exceed the size limit
                    labelMaxFileSize="Maximum file size is 5MB" // Message showing the max file size
                    labelFileTypeNotAllowed="Invalid file type" // Custom message for disallowed file types
                    fileValidateTypeLabelExpectedTypes="Expects JPEG or PNG" // Message showing the expected file types
                  />
                </div>
              </FormControl>
            </Grid>
            <hr />
            <Grid item xs={12}>
              <InputLabel className="inputLabel">Write a post</InputLabel>
              <FormControl style={{ width: "100%" }}>
                <Controller
                  name="review"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tell us about your experience..."
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <div className="formButtons">
                <button className="submitButton" type="submit">
                  Submit
                </button>
                <button
                  className="cancelButton"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default NewCruiseItem;
