import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/footer";

const Legal = () => (
  <>
    <Navbar />
    <div style={{ backgroundColor: "#bbcfdd" }}>
      <div
        style={{
          padding: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          marginTop: "60px",
          marginBottom: "50px",
          backgroundColor: "#bbcfdd",
        }}
      >
        <h1>Terms of Service for Kaleido Travel Vlogs</h1>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing, browsing, or using Kaleido Travel Vlogs ("Site"), you
          agree to comply with and be bound by these Terms of Service (TOS) and
          all applicable laws and regulations. If you do not agree to these
          terms, you must not use the Site.
        </p>

        <h2>2. Changes to Terms</h2>
        <p>
          We may revise these TOS at any time without notice. By using this
          Site, you agree to be bound by the current version of these Terms and
          Conditions of Use.
        </p>

        <h2>3. User Registration</h2>
        <p>
          To access certain features of the Site, you may be required to
          register. You agree to provide accurate and current information and to
          update that information as necessary to maintain its accuracy.
        </p>

        <h2>4. User Content</h2>
        <p>
          You may submit videos, photos, and descriptions ("Content") to the
          Site. You represent and warrant that you own or have the necessary
          permissions to use and authorize the use of your Content as described
          herein. You may not submit unlawful, offensive, or otherwise
          inappropriate Content.
        </p>

        <h2>5. Copyright and Intellectual Property Rights</h2>
        <p>
          All content on this Site, including text, graphics, logos, images, and
          software, is the property of Kaleido Travel Vlogs or its content
          suppliers and protected by international copyright laws. You may not
          duplicate, copy, or reuse any portion of the HTML, visual design
          elements, or other content without express written permission.
        </p>

        <h2>6. Third-Party Links and Embeds</h2>
        <p>
          The Site may contain links to third-party websites or embedded content
          from other services like YouTube. We are not responsible for the
          content, privacy policies, or practices of any third-party websites.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          In no event shall Kaleido Travel Vlogs or its suppliers be liable for
          any damages (including, without limitation, damages for loss of data
          or profit) arising out of the use or inability to use the materials on
          the Site, even if Kaleido Travel Vlogs has been notified of the
          possibility of such damage.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These terms and conditions are governed by the laws of your
          jurisdiction and you irrevocably submit to the exclusive jurisdiction
          of the courts in that State or location.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          For questions about these Terms of Service, please contact us at:{" "}
          <a href="mailto:jamie@kaleidotravelvlogs.com">
            jamie@kaleidotravelvlogs.com
          </a>
        </p>

        <section></section>
        <h1>Privacy Policy</h1>
        {/* Privacy Policy Content */}
        <section>
          <h1>Privacy Policy for Kaleido Travel Vlogs</h1>
          <p>Last Updated: 13/08/2023</p>

          <p>
            Welcome to Kaleido Travel Vlogs! We understand that privacy online
            is important to users of our Site, especially when conducting
            business. This statement governs our privacy policies concerning
            those users of the Site (“Visitors”) who visit without transacting
            business and Visitors who register to transact business on the Site
            and make use of the various services offered by Kaleido Travel Vlogs
            (collectively, “Services”).
          </p>

          <h2>1. Personally Identifiable Information</h2>
          <p>
            Kaleido Travel Vlogs collects basic user profile information from
            all our Visitors. When you create an account with us, you are
            providing us with your personal information, including but not
            limited to your name, email address, and other identifying
            information.
          </p>
          <p>
            Additionally, when you submit a journey, video, or photo, we collect
            the data you provide, which may include personal experiences,
            locations visited, and any media you submit.
          </p>

          <h2>2. Use of Information</h2>
          <p>
            We use personally identifiable information to customize the Site, to
            make appropriate service offerings, and to fulfill buying and
            selling requests on the Site. We may email Visitors and Authorized
            Customers about research or purchase and selling opportunities on
            the Site or information related to the subject matter of the Site.
          </p>

          <h2>3. Third-Party Websites</h2>
          <p>
            Our Site includes links to other websites whose privacy practices
            may differ from ours. If you submit personal information to any of
            those sites, your information is governed by their privacy policies.
            We encourage you to carefully read the privacy policy of any website
            you visit.
          </p>

          <h2>4. Embedded Videos</h2>
          <p>
            Videos embedded on our platform from YouTube are subject to
            YouTube's privacy policies. We do not have control over the privacy
            of those videos, and users should be aware of YouTube's terms when
            viewing or embedding videos.
          </p>

          <h2>5. Security</h2>
          <p>
            The security of your personal information is important to us. We
            follow industry standards to protect the personal information
            submitted to us, both during transmission and once we receive it.
            However, no method of transmission over the Internet, or method of
            electronic storage, is 100% secure. Therefore, we cannot guarantee
            its absolute security.
          </p>

          <h2>6. Changes to this Policy</h2>
          <p>
            We may update this privacy policy to reflect changes to our
            information practices. We encourage you to periodically review this
            page for the latest information on our privacy practices.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            If you have questions or concerns about our privacy policy, please
            contact us at:{" "}
            <a href="mailto:jamie@kaleidotravelvlogs.com">
              jamie@kaleidotravelvlogs.com
            </a>
          </p>
        </section>
        <h1>Upload Policy</h1>
        {/* Upload Policy Content */}
        <section>
          <h1>Kaleido Travel Vlogs Upload Policy</h1>
          <p>Last Updated: 13/08/2023</p>

          <h2>1. Acceptance of Upload Policy</h2>
          <p>
            By submitting or embedding videos, photos, or other content
            ("Content") to Kaleido Travel Vlogs ("Site"), you agree to comply
            with this Upload Policy.
          </p>

          <h2>2. Content Guidelines</h2>
          <p>
            All Content submitted to the Site must adhere to the following
            guidelines:
          </p>
          <ul>
            <li>
              Content must be relevant to the purpose and theme of the Site.
            </li>
            <li>
              Content must not contain violence, hate speech, explicit material,
              or other content that may be deemed offensive.
            </li>
            <li>
              Content must not infringe on the intellectual property rights,
              privacy, or other rights of third parties.
            </li>
          </ul>

          <h2>3. YouTube Embedding</h2>
          <p>If embedding videos from YouTube:</p>
          <ul>
            <li>
              You must be the owner of the content or have the necessary rights
              and permissions to share it.
            </li>
            <li>
              You must comply with YouTube's Terms of Service and Community
              Guidelines.
            </li>
            <li>
              You must ensure that the embedded content follows all relevant
              laws and regulations.
            </li>
          </ul>

          <h2>4. User Responsibilities</h2>
          <p>
            You are solely responsible for the Content you upload or embed and
            for ensuring that it complies with this Upload Policy and all
            applicable laws and regulations. You represent and warrant that you
            have the legal right to submit the Content and that it does not
            violate any copyrights, trademarks, or other intellectual property
            rights.
          </p>

          <h2>5. Rights to Use Content</h2>
          <p>
            By submitting or embedding Content, you grant Kaleido Travel Vlogs a
            worldwide, non-exclusive, royalty-free license to use, reproduce,
            distribute, and display the Content in connection with the Site.
          </p>

          <h2>6. Removal of Content</h2>
          <p>
            We reserve the right, at our sole discretion, to remove or disable
            access to any Content that violates this Upload Policy or that we
            deem inappropriate for any reason, without prior notice to you.
          </p>

          <h2>7. Reporting Violations</h2>
          <p>
            If you believe that any Content violates this Upload Policy or
            infringes upon your rights, please contact us at:{" "}
            <a href="mailto:jamie@kaleidotravelvlogs.com">
              jamie@kaleidotravelvlogs.com
            </a>{" "}
            with detailed information about the alleged violation.
          </p>

          <h2>8. Changes to Upload Policy</h2>
          <p>
            We may update this Upload Policy at any time, and your continued
            submission or embedding of Content constitutes acceptance of those
            changes.
          </p>
        </section>
      </div>
    </div>
    <Footer />
  </>
);

export default Legal;
