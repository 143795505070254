import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import HomeIcon from "@mui/icons-material/Home";
import { db, auth } from "../../firebase.js";
import {
  doc,
  getDoc,
  collection,
  deleteDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import Map from "../../Components/homeMap/homeMap";
import Swal from "sweetalert2";
import JourneyForm from "../../Components/items/newItem.js";
import NewJourney from "../../Components/items/newJourney.js";
import UserContext from "../../UserContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Sidebar from "../../Components/Sidebar/sideBar.js";
import RightSidebar from "../../Components/Sidebar/rightSideBar.js";
import {
  Button,
  IconButton,
  Popover,
  Modal,
  Fab,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./HomePage.css";
console.log(db);

function HomePage() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openJourney, setOpenJourney] = useState(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) =>
      setIsSignedIn(!!user)
    );
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClosePopover2 = () => {
    setAnchorEl2(null);
  };

  const openPopover = Boolean(anchorEl);
  const openPopover2 = Boolean(anchorEl2);
  const id = openPopover ? "simple-popover" : undefined;
  const id2 = openPopover2 ? "simple-popover-2" : undefined;

  const handleOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleOpenJourney = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add a journey",
      });
    } else {
      setOpenJourney(true);
    }
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "experience") {
      handleOpen();
    } else if (action === "journey") {
      handleOpenJourney();
    }
  };

  // Utility function to aggregate all dream items from all lists
  const getAllDreamItems = (lists = []) => {
    return lists.reduce((acc, list) => {
      if (Array.isArray(list.items)) {
        return acc.concat(list.items);
      }
      return acc;
    }, []);
  };

  const handleDeleteList = async (listId) => {
    try {
      // Delete the document from the DreamLists collection
      const listRef = doc(db, "DreamLists", listId);
      await deleteDoc(listRef);

      // Remove the reference from the user's document
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        dreamLists: arrayRemove(listId),
      });

      // Update the state after deletion
      setDreamLists((prevLists) =>
        prevLists.filter((list) => list.id !== listId)
      );
    } catch (error) {
      console.error("Error deleting dream list:", error);
      Swal.fire("Error", "Failed to delete the dream list.", "error");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <Map items={items} />
      <div className="fullWidthHome">
        <Sidebar
          dreamLists={dreamLists}
          journeys={journeys}
          onDelete={handleDeleteList}
          setDreamLists={setDreamLists}
          user={user}
        />
        <div className="homePageContainer">
          <header className="App-header">
            <img
              src="../../favicon.ico"
              alt="Kaleido Travel"
              className="logo"
            />
            <div style={{ marginTop: "20px" }}>
              <div className="tagLine">
                <p className="homeText">Kaleido</p>
                <p className="highlight"> Travel</p>
              </div>
              <p className="heroP">
                Your interactive diary for sharing your journeys and inspiring
                perfect travel experiences for our community.
              </p>
            </div>
            <JourneyItem
              userId={user ? user.uid : null}
              user={user ? user : null}
              setItems={setItems}
              items={items}
              refresh={refresh} // This will be used to refresh the feed after a new item is added
              setRefresh={setRefresh}
            />
          </header>
        </div>
        <RightSidebar />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
