/*eslint-disable*/
import React, { useContext, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Bookmark from "@mui/icons-material/Bookmark";
import InfoIcon from "@mui/icons-material/Info";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import FabMenu from "../Add/FabMenu";
import Swal from "sweetalert2";
import JourneyForm from "../items/newItem";
import NewJourney from "../items/newJourney";
import CheckIn from "../Add/CheckIn";

export default function Footer(props) {
  const user = useContext(UserContext);
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [anchorElAbout, setAnchorElAbout] = useState(null);
  const [anchorElJourneys, setAnchorElJourneys] = useState(null);
  const [anchorElDreamLists, setAnchorElDreamLists] = useState(null);
  const [open, setOpen] = useState(false);
  const [openJourney, setOpenJourney] = useState(false);
  const [isCheckInOpen, setIsCheckInOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleMenuClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Not Logged In",
        text: "You need to be logged in to perform this action.",
      });
      return;
    }

    if (item === "experience") {
      setOpen(true);
    } else if (item === "journey") {
      setOpenJourney(true);
    } else if (item === "checkIn") {
      setIsCheckInOpen(true);
    }
  };

  const handleClose = () => setOpen(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const handleCheckInClose = () => setIsCheckInOpen(false);

  return (
    <footer style={footerStyle}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        style={bottomNavigationStyle}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon sx={{ color: "white" }} />}
          onClick={() => handleNavigate("/")}
          style={{ color: "white" }}
        />
        <BottomNavigationAction
          label="About"
          icon={<InfoIcon sx={{ color: "white" }} />}
          onClick={(event) => handleMenuClick(event, setAnchorElAbout)}
          style={{ color: "white" }}
        />
        <Menu
          anchorEl={anchorElAbout}
          open={Boolean(anchorElAbout)}
          onClose={() => handleMenuClose(setAnchorElAbout)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => handleNavigate("/about")}>About</MenuItem>
          <MenuItem
            onClick={() =>
              handleNavigate("mailto:jamie@kaleidotravelvlogs.com")
            }
          >
            Contact
          </MenuItem>
          <MenuItem onClick={() => handleNavigate("/legal")}>Legal</MenuItem>
          <MenuItem
            onClick={() =>
              handleNavigate("https://discord.com/channels/kaleidotravel3749")
            }
          >
            Discord
          </MenuItem>
        </Menu>
        <FabMenu onMenuItemClick={handleMenuItemClick} />
        <BottomNavigationAction
          label="Journeys"
          icon={<TravelExploreIcon sx={{ color: "white" }} />}
          onClick={(event) => handleMenuClick(event, setAnchorElJourneys)}
          style={{ color: "white" }}
        />
        <Menu
          anchorEl={anchorElJourneys}
          open={Boolean(anchorElJourneys)}
          onClose={() => handleMenuClose(setAnchorElJourneys)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {user ? (
            journeys && journeys.length > 0 ? (
              journeys.map((journey, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleNavigate(`/journey/${journey.id}`)}
                >
                  {journey.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No journeys available</MenuItem>
            )
          ) : (
            <MenuItem disabled>Please sign in to view your journeys</MenuItem>
          )}
        </Menu>
        <BottomNavigationAction
          label="Dream Lists"
          icon={<Bookmark sx={{ color: "white" }} />}
          onClick={(event) => handleMenuClick(event, setAnchorElDreamLists)}
          style={{ color: "white" }}
        />
        <Menu
          anchorEl={anchorElDreamLists}
          open={Boolean(anchorElDreamLists)}
          onClose={() => handleMenuClose(setAnchorElDreamLists)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {user ? (
            dreamLists && dreamLists.length > 0 ? (
              dreamLists.map((list, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleNavigate(`/dreamList/${list.id}`)}
                >
                  {list.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No dream lists available</MenuItem>
            )
          ) : (
            <MenuItem disabled>
              Please sign in to view your dream lists
            </MenuItem>
          )}
        </Menu>
      </BottomNavigation>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <JourneyForm
          user={user ? user : null}
          onClose={handleClose}
          setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
        />
      </Modal>
      <Modal
        open={openJourney}
        onClose={handleCloseJourney}
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <NewJourney
          user={user ? user : null}
          onClose={handleCloseJourney}
          setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
        />
      </Modal>
      <Modal
        open={isCheckInOpen}
        onClose={() => setIsCheckInOpen(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <CheckIn
          open={isCheckInOpen}
          onClose={() => setIsCheckInOpen(false)}
          refreshUser={setRefresh}
        />
      </Modal>
    </footer>
  );
}

const footerStyle = {
  position: "fixed",
  bottom: 0,
  width: "100vw",
  backgroundColor: "#1bb6ca",
  color: "#ffff",
  padding: "10px 0",
  zIndex: "1",
};

const bottomNavigationStyle = {
  backgroundColor: "#1bb6ca",
  color: "#ffff",
};
