import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import HomeIcon from "@mui/icons-material/Home";
import { db, auth } from "../../firebase.js";
import {
  doc,
  getDoc,
  collection,
  deleteDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import Map from "../../Components/homeMap/homeMap";
import Swal from "sweetalert2";
import JourneyForm from "../../Components/items/newItem.js";
import NewJourney from "../../Components/items/newJourney.js";
import UserContext from "../../UserContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Sidebar from "../../Components/Sidebar/sideBar.js";
import RightSidebar from "../../Components/Sidebar/rightSideBar.js";
import {
  Button,
  IconButton,
  Popover,
  Modal,
  Fab,
  Menu,
  MenuItem,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./AboutPage.css";
console.log(db);

function AboutPage() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openJourney, setOpenJourney] = useState(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) =>
      setIsSignedIn(!!user)
    );
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClosePopover2 = () => {
    setAnchorEl2(null);
  };

  const openPopover = Boolean(anchorEl);
  const openPopover2 = Boolean(anchorEl2);
  const id = openPopover ? "simple-popover" : undefined;
  const id2 = openPopover2 ? "simple-popover-2" : undefined;

  const handleOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleOpenJourney = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add a journey",
      });
    } else {
      setOpenJourney(true);
    }
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "experience") {
      handleOpen();
    } else if (action === "journey") {
      handleOpenJourney();
    }
  };

  // Utility function to aggregate all dream items from all lists
  const getAllDreamItems = (lists = []) => {
    return lists.reduce((acc, list) => {
      if (Array.isArray(list.items)) {
        return acc.concat(list.items);
      }
      return acc;
    }, []);
  };

  const handleDeleteList = async (listId) => {
    try {
      // Delete the document from the DreamLists collection
      const listRef = doc(db, "DreamLists", listId);
      await deleteDoc(listRef);

      // Remove the reference from the user's document
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        dreamLists: arrayRemove(listId),
      });

      // Update the state after deletion
      setDreamLists((prevLists) =>
        prevLists.filter((list) => list.id !== listId)
      );
    } catch (error) {
      console.error("Error deleting dream list:", error);
      Swal.fire("Error", "Failed to delete the dream list.", "error");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <div className="fullWidthHomeAbout">
        <Sidebar
          dreamLists={dreamLists}
          journeys={journeys}
          onDelete={handleDeleteList}
          setDreamLists={setDreamLists}
          user={user}
        />
        <div className="homePageContainer">
          <header className="App-header">
            <div style={{ marginTop: "20px" }}>
              <div className="tagLine">
                <p className="homeText">Kaleido</p>
                <p className="highlight"> Travel</p>
              </div>
              <p className="heroP">
                Your interactive diary for sharing your journeys and inspiring
                perfect travel experiences for our community.
              </p>
            </div>
            <Container
              maxWidth="md"
              sx={{
                mt: 4,
                mb: 4,
                backgroundColor: "#bbcfdd",
              }}
            >
              <p className="homeText" style={{ marginBottom: "20px" }}>
                About Kaleido
              </p>
              <Card
                sx={{
                  mb: 4,
                  backgroundColor: "white",
                  borderRadius: 10,
                  boxShadow: 3,
                  border: "5px solid #1bb6ca",
                  color: "#415063",
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Welcome to Kaleido!
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Kaleido is your interactive diary for sharing your journeys
                    and inspiring perfect travel experiences for our community.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Whether you're planning your next adventure, dreaming of
                    far-off places, or sharing your latest travel experiences,
                    Kaleido is here to help you every step of the way.
                  </Typography>
                </CardContent>
              </Card>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mb: 4,
                      backgroundColor: "white",
                      borderRadius: 10,
                      boxShadow: 3,
                      border: "5px solid #1bb6ca",
                      color: "#415063",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="h3" gutterBottom>
                        Journey
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Kaleido's "Journeys" feature lets you capture your
                        entire trip experience in one place. Start by saving
                        your full itinerary, which maps out your route and
                        planned destinations. As you explore, you can add unique
                        experiences, such as visits to restaurants, landmarks,
                        and activities, all stored within your journey. Each
                        journey becomes a story you can share, complete with
                        memories, insights, and visuals, making it easy for
                        friends and fellow travelers to follow along or draw
                        inspiration for their own adventures.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mb: 4,
                      backgroundColor: "white",
                      borderRadius: 10,
                      boxShadow: 3,
                      border: "5px solid #1bb6ca",
                      color: "#415063",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="h3" gutterBottom>
                        Share Your Experiences
                      </Typography>
                      <Typography variant="body1" paragraph>
                        With Kaleido's "Experience" feature, you can capture and
                        share each individual moment of your journey. Document
                        the unique experiences you've had, whether at a
                        restaurant, theme park, cruise, or destination. Save
                        photos, link videos from YouTube, Instagram, or TikTok,
                        and organize them into categories like reviews, venue
                        tours, or menu highlights. You can also add a personal
                        write-up about each experience and share any standout
                        stats from your visit. Share these moments with the
                        community and create a shareable image to post on social
                        media, inspiring others and adding your own insights to
                        the Kaleido Travel community.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mb: 4,
                      backgroundColor: "white",
                      borderRadius: 10,
                      boxShadow: 3,
                      border: "5px solid #1bb6ca",
                      color: "#415063",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="h3" gutterBottom>
                        Discover New Destinations
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Discover new destinations through the experiences shared
                        by other travelers. Find inspiration for your next trip
                        and add new places to your travel bucket list.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mb: 4,
                      backgroundColor: "white",
                      borderRadius: 10,
                      boxShadow: 3,
                      border: "5px solid #1bb6ca",
                      color: "#415063",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="h3" gutterBottom>
                        Dream Lists
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Dream Lists on Kaleido are a place to store and organize
                        all the destinations, activities, and experiences you
                        aspire to try one day. It’s your travel bucket list
                        where you can add anything from remote islands to iconic
                        theme parks, culinary adventures, road trips, and
                        beyond. Each item on your Dream List can be as detailed
                        or as simple as you like.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Get Started
                </Typography>
                <Typography variant="body1" paragraph sx={{ color: "#415063" }}>
                  Ready to start your journey with Kaleido? Sign up today and
                  join our community of travelers!
                </Typography>
              </Box>
            </Container>
          </header>
        </div>
        <RightSidebar />
        <Footer />
      </div>
    </div>
  );
}

export default AboutPage;
