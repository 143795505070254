import React, { useState, useEffect } from "react";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { Paper } from "@mui/material";
import { CommentSection } from "react-comments-section";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase.js";
import Swal from "sweetalert2";
import "react-comments-section/dist/index.css";
import "./Chat.css";
import { Margin, Padding } from "@mui/icons-material";

const firestore = getFirestore(); // Initialize Firestore

const CruiseChat = ({ item, user }) => {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(true);
  const [loadingItem, setLoadingItem] = useState(true);

  useEffect(() => {
    if (item && item.id) {
      setLoadingItem(false);
    }
  }, [item]);

  useEffect(() => {
    if (!loadingItem) {
      const fetchComments = async () => {
        const docRef = doc(db, "CruiseJourneys", item.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setComments(data.Comments || []);
        }
        setLoadingComments(false);
      };

      fetchComments();
    }
  }, [loadingItem, item]);

  useEffect(() => {
    const interval = setInterval(() => {
      const submitButton = document.querySelector(".rcs-submit-button");
      if (submitButton) {
        submitButton.style.marginTop = "0";
      }
      const overlay = document.querySelector(".rcs-input-box .overlay");
      if (overlay) {
        overlay.style.width = "100%";
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const CustomInput = ({ handleChange, value }) => (
    <input
      type="text"
      placeholder="Type your comment here..."
      value={value}
      onChange={handleChange}
      className="rcs-input"
    />
  );

  if (loadingItem || loadingComments) {
    return <div>Loading...</div>;
  }

  const currentUser = user
    ? {
        currentUserId: user.uid,
        currentUserImg:
          user.photoURL || "https://ui-avatars.com/api/?name=" + user.userName,
        currentUserProfile: user.facebook || "#",
        currentUserFullName: user.userName || user.email,
      }
    : null;

  const handleCommentSubmit = async (data) => {
    const newComments = [...comments, data];
    setComments(newComments);

    const docRef = doc(db, "CruiseJourneys", item.id);
    await updateDoc(docRef, {
      Comments: newComments,
    });
  };

  const handleReplySubmit = async (data, parentId) => {
    const newComments = comments.map((comment) => {
      if (comment.comId === parentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), data],
        };
      }
      return comment;
    });
    setComments(newComments);

    const docRef = doc(db, "CruiseJourneys", item.id);
    await updateDoc(docRef, {
      Comments: newComments,
    });
  };

  const handleEditSubmit = async (data, commentId, parentId = null) => {
    let newComments;
    if (parentId) {
      newComments = comments.map((comment) => {
        if (comment.comId === parentId) {
          return {
            ...comment,
            replies: comment.replies.map((reply) =>
              reply.comId === commentId ? { ...reply, ...data } : reply
            ),
          };
        }
        return comment;
      });
    } else {
      newComments = comments.map((comment) =>
        comment.comId === commentId ? { ...comment, ...data } : comment
      );
    }
    setComments(newComments);

    const docRef = doc(db, "CruiseJourneys", item.id);
    await updateDoc(docRef, {
      Comments: newComments,
    });
  };

  const handleDeleteSubmit = async (data) => {
    const { comIdToDelete, parentOfDeleteId } = data;
    console.log(
      "Deleting comment with ID:",
      comIdToDelete,
      "Parent ID:",
      parentOfDeleteId
    );

    if (!comIdToDelete) {
      console.error("Error: comIdToDelete is undefined");
      return;
    }

    console.log("Current comments array:", comments);

    let newComments;
    if (parentOfDeleteId) {
      // Deleting a reply
      console.log(
        "Deleting a reply with ID:",
        comIdToDelete,
        "from parent ID:",
        parentOfDeleteId
      );
      newComments = comments.map((comment) => {
        if (comment.comId === parentOfDeleteId) {
          const updatedReplies = comment.replies.filter(
            (reply) => reply.comId !== comIdToDelete
          );
          console.log(
            "Updated replies for parent ID:",
            parentOfDeleteId,
            updatedReplies
          );
          return {
            ...comment,
            replies: updatedReplies,
          };
        }
        return comment;
      });
    } else {
      // Deleting a parent comment
      console.log("Deleting a parent comment with ID:", comIdToDelete);
      newComments = comments.filter((comment) => {
        const shouldDelete = comment.comId !== comIdToDelete;
        console.log(
          `Checking comment with ID: ${comment.comId}, should delete: ${shouldDelete}`
        );
        return shouldDelete;
      });
    }

    console.log("Updated comments array after deletion:", newComments);
    setComments(newComments);

    try {
      const docRef = doc(db, "CruiseJourneys", item.id);
      await updateDoc(docRef, {
        Comments: newComments,
      });
      console.log("Firestore updated successfully with new comments array");
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const showLoginAlert = () => {
    Swal.fire({
      title: "Sign in",
      text: "You need to sign in to write comments.",
      icon: "info",
      zIndex: 2000,
    });
  };

  return (
    <div className="feedItem">
      <Paper elevation={24} className="hand-drawn-rectangle">
        <div className="topHalf">
          <div className="cruisItemInfo">
            <h2 className="itemHeader">Chat</h2>
          </div>
          <div className="chatIntroContainer">
            <p className="chatIntro">
              Introduce yourself, ask a question, or get to know your fellow
              cruisers!
            </p>
          </div>
        </div>
        <div className="itemBody">
          <CommentSection
            currentUser={currentUser}
            overlayStyle={{ width: "100%", Padding: "0" }}
            formStyle={{ Padding: "10px !important", flexWrap: "wrap" }}
            inputStyle= {{ minWidth: "200px", margin: "10px" }}
            replyInputStyle={{ minWidth: "200px", margin: "10px" }}
            logInStyle={{ display: "none" }}
            imgStyle={{ display: "none" }}
            commentData={comments}
            onSubmitAction={currentUser ? handleCommentSubmit : null}
            onReplyAction={currentUser ? handleReplySubmit : null}
            onEditAction={currentUser ? handleEditSubmit : null}
            onDeleteAction={currentUser ? handleDeleteSubmit : null}
            customInput={currentUser ? CustomInput : null}
            replyInput={currentUser ? CustomInput : null}
            logIn={{}}
            headerText={null}
          />
        </div>
      </Paper>
    </div>
  );
};

export default CruiseChat;
