import React, { useState, useContext } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import Swal from "sweetalert2";
import UserContext from "../../UserContext";
import { db } from "../../firebase";
import PlacesAutocomplete from "react-places-autocomplete";
import { useMediaQuery } from "@mui/material";

const CheckIn = ({ open, onClose, refreshUser }) => {
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [locationType, setLocationType] = useState("");
  const [checkInNote, setCheckInNote] = useState("");
  const user = useContext(UserContext);
  const context = useContext(UserContext);
  const { journeys } = context || {};
  const isMobile = useMediaQuery("(max-width:600px)");

  const handlePlaceSelect = (address, placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId: placeId }, (placeResult, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        let country = null;
        let state = null;
        let city = null;
        let countryCode = null;

        placeResult.address_components.forEach((component) => {
          if (component.types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
        });

        const locationData = {
          name: placeResult.name,
          address: placeResult.formatted_address,
          lat: placeResult.geometry.location.lat(),
          lng: placeResult.geometry.location.lng(),
          country: country,
          state: state,
          city: city,
          countryCode: countryCode,
        };
        setSelectedLocation(locationData);
        setAutocompleteValue(address);
      } else {
        console.error(
          "Error: No location data available for this place.",
          "Status:",
          status,
          "Place:",
          placeResult
        );
      }
    });
  };

  const handleSubmit = async () => {
    if (user && selectedLocation) {
      try {
        const userRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        const currentCheckIn = userData.CheckIn || null;
        const history = currentCheckIn ? currentCheckIn.history || [] : [];

        if (currentCheckIn) {
          // Avoid circular references by creating a shallow copy of the current check-in
          const currentCheckInCopy = { ...currentCheckIn };
          delete currentCheckInCopy.history; // Remove the history property to avoid nesting
          history.push(currentCheckInCopy);
        }

        const newCheckIn = {
          ...selectedLocation,
          locationType: locationType || null,
          journey: {
            id: selectedJourney.id || null,
            name: selectedJourney.name || null,
            location: selectedJourney.location || null,
          },
          note: checkInNote,
          createdAt: Timestamp.now(), // Add the current timestamp
          startDate: Timestamp.now(),
          userId: user.uid, // Reference to the user
          userName: user.userName,
          category: "Check-In",
        };

        // Add the check-in to the CheckIns collection
        await addDoc(collection(db, "JourneyItems"), newCheckIn);

        // Update the user's check-in data
        await updateDoc(userRef, {
          CheckIn: {
            ...selectedLocation,
            journey: selectedJourney || null,
            note: checkInNote,
            timestamp: Timestamp.now(), // Add the current timestamp
            history: history,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Check-In Successful",
          text: "Your check-in has been saved successfully.",
        });

        onClose();
      } catch (error) {
        console.error("Error saving check-in:", error);
        Swal.fire({
          icon: "error",
          title: "Check-In Failed",
          text: "There was an error saving your check-in. Please try again.",
        });
      }
    }
  };
  const handleLocationTypeChange = (event) => {
    setLocationType(event.target.value);
  };

  const handleReset = () => {
    setAutocompleteValue("");
    setSelectedLocation(null);
    setSelectedJourney(null);
    setLocationType("");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="check-in-modal-title"
      aria-describedby="check-in-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "320px" : "500px",
          bgcolor: "background.paper",
          border: "5px solid #1bb6ca",
          borderRadius: 5,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="check-in-modal-title" variant="h6" component="h2">
          Check-In
        </Typography>
        <Typography id="check-in-modal-description" sx={{ mt: 2 }}>
          Where are you?
        </Typography>
        <PlacesAutocomplete
          value={autocompleteValue}
          onChange={setAutocompleteValue}
          onSelect={handlePlaceSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Search for your location...",
                  className: "location-input",
                  style: {
                    width: "100%",
                    height: "40px",
                    padding: "10px",
                    marginTop: "10px",
                  },
                })}
              />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    cursor: "pointer",
                  };

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                        key: suggestion.placeId,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="journey-label">Journey (Optional)</InputLabel>
          <Select
            labelId="journey-label"
            id="journey"
            value={selectedJourney || ""}
            label="Journey"
            onChange={(event) => setSelectedJourney(event.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {journeys &&
              journeys.length > 0 &&
              journeys.map((journey, index) => (
                <MenuItem key={index} value={journey}>
                  {journey.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <h2 style={{ margin: "10px" }}>Or</h2>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="location-type-label">Location Type</InputLabel>
          <Select
            labelId="location-type-label"
            id="location-type"
            value={locationType}
            label="Location Type"
            onChange={handleLocationTypeChange}
          >
            <MenuItem value="Home">Home</MenuItem>
            <MenuItem value="Planning">Planning</MenuItem>
            <MenuItem value="Dreaming">Dreaming</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Write about your check-in"
          value={checkInNote}
          onChange={(event) => setCheckInNote(event.target.value)}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedLocation}
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReset}
          sx={{ mt: 2, ml: 2 }}
        >
          Reset
        </Button>
      </Box>
    </Modal>
  );
};

export default CheckIn;
