import React, { forwardRef } from "react";
import { format, fromUnixTime } from "date-fns";
import "./InstagramShareImage.css";
import CruiseBG from "./CruiseBG.png";

const InstagramShareImage = forwardRef(({ item, imageUrl, timeLeft }, ref) => {
  return (
    <div
      ref={ref}
      className="instagram-share-image"
      style={{
        backgroundImage: `url(${CruiseBG})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        borderRadius: "10px",
        border: "4px solid #1bb6ca",
      }}
    >
      <div className="share-content">
        <img src={imageUrl} alt="cruise" className="share-image" />
        <h2 className="share-title">{item.cruise.name}</h2>
        <p className="share-title">{item.ship.name}</p>
        <p className="share-desc">Departing from {item.departurePort}</p>
        {item.departureDate && (
          <p className="share-desc">
            {format(
              fromUnixTime(item.departureDate / 1000),
              "do 'of' MMMM yyyy"
            )}
            {item.endDate &&
              ` to ${format(
                fromUnixTime(item.endDate / 1000),
                "do 'of' MMMM yyyy"
              )}`}
          </p>
        )}

        {/* Countdown Timer */}
        <div className="countdown-box">
          <div>
            {timeLeft ? (
              <>
                <h2 className="share-title" style={{ color: "white" }}>
                  Countdown to Departure
                </h2>
                <h2 className="share-title" style={{ color: "white" }}>
                  {timeLeft.days} Days | {timeLeft.hours} Hours |{" "}
                  {timeLeft.minutes} Minutes
                </h2>
              </>
            ) : (
              <h2 className="share-title" style={{ color: "white" }}>
                Explore the full cruise diary and photos on Kaleido!
              </h2>
            )}
          </div>
        </div>

        {/* Enhanced Call to Action */}
        <p className="share-call-to-action">
          Track your cruise with us at Kaleido – where every moment becomes a
          memory!
        </p>
        <p
          className="share-call-to-action"
        >
          Visit: kaleidotravelvlogs.com/Cruise
        </p>
      </div>
    </div>
  );
});

export default InstagramShareImage;
