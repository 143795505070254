import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Typography,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Bookmark, AddCircleOutline } from "@mui/icons-material";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import AddMenu from "../Add/AddMenu";
import CheckIn from "../Add/CheckIn";
import {
  doc,
  deleteDoc,
  updateDoc,
  arrayRemove,
  collection,
  setDoc,
  arrayUnion,
} from "firebase/firestore";

const Sidebar = ({
  journeys,
  dreamLists,
  onDelete,
  setDreamLists,
  user,
  refreshUser,
}) => {
  const [journeysState, setJourneys] = useState(journeys);
  const [loading, setLoading] = useState(true);
  const [openCheckIn, setOpenCheckIn] = useState(false);

  const navigate = useNavigate();

  const handleDelete = (dreamList) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete the dream list "${dreamList.name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(dreamList.id);
        Swal.fire("Deleted!", "Your dream list has been deleted.", "success");
      }
    });
  };
  useEffect(() => {
    if (!user || (journeys && dreamLists)) {
      setLoading(false);
    }
  }, [user, journeys, dreamLists]);

  if (loading) {
    return (
      <div className="sideBar">
        <div className="loadingContainer">
          <CircularProgress />
        </div>
      </div>
    );
  }

  const handleNavigateDreamList = (dreamListId) => {
    navigate(`/dreamList/${dreamListId}`);
  };

  const handleNavigateJourney = (journeyId) => {
    navigate(`/journey/${journeyId}`);
  };

  const handleDeleteJourney = async (journey) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete the journey "${journey.name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Delete the journey document from the database
          const journeyRef = doc(db, "Journeys", journey.id);
          await deleteDoc(journeyRef);

          // Remove the reference from the user's document
          const userRef = doc(db, "Users", journey.userId);
          await updateDoc(userRef, {
            journeys: arrayRemove(journey.id),
          });

          // Update the state after deletion
          setJourneys((prevJourneys) =>
            prevJourneys.filter((j) => j.id !== journey.id)
          );

          Swal.fire("Deleted!", "Your journey has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting journey:", error);
          Swal.fire("Error", "Failed to delete the journey.", "error");
        }
      }
    });
  };

  const handleCreateDreamList = async () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Not Logged In",
        text: "You need to be logged in to create a dream list.",
      });
      return;
    }

    const { value: listName } = await Swal.fire({
      title: "Create New Dream List",
      input: "text",
      inputPlaceholder: "Enter new list name",
      showCancelButton: true,
      confirmButtonText: "Create",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value.trim()) {
          return "Please enter a name for the new list";
        }
      },
    });

    if (listName) {
      try {
        const newListRef = doc(collection(db, "DreamLists"));
        const newListId = newListRef.id;

        await setDoc(newListRef, {
          name: listName.trim(),
          userId: user.uid,
          id: newListId,
          items: [],
        });

        const userRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          dreamLists: arrayUnion(newListId),
        });

        // Update the state after creation
        setDreamLists((prevLists) => [
          ...prevLists,
          { id: newListId, name: listName.trim(), items: [] },
        ]);

        Swal.fire(
          "Created!",
          "Your new dream list has been created.",
          "success"
        );
      } catch (error) {
        console.error("Error creating dream list:", error);
        Swal.fire("Error", "Failed to create the dream list.", "error");
      }
    }
  };

  const handleCheckInOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Not Logged In",
        text: "You need to be logged in to check in.",
      });
      return;
    }
    setOpenCheckIn(true);
  };

  const handleCheckInClose = () => {
    setOpenCheckIn(false);
  };

  return (
    <div className="sideBar">
      <div className="tagLine">
        <p
          className="homeText"
          style={{ fontSize: "1.7rem", marginBottom: "15px", color: "white" }}
        >
          Dashboard
        </p>
      </div>
      <Link to="/">
        <Button
          variant="contained"
          startIcon={<HomeIcon />}
          sx={{ backgroundColor: "#1bb6ca", marginBottom: "10px" }}
          className="sideBarButton"
        >
          Home
        </Button>
      </Link>
      <Link to="/Cruise">
        <Button
          variant="contained"
          startIcon={<DirectionsBoatIcon />}
          sx={{ backgroundColor: "#1bb6ca", marginBottom: "10px" }}
          className="sideBarButton"
        >
          Cruise
        </Button>
      </Link>
      <Accordion className="sideBarAccordion">
        <AccordionSummary
          className="sideBarAccordionButton"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            <TravelExploreIcon /> My Journeys
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {user ? (
              journeys && journeys.length > 0 ? (
                journeys.map((journey, index) => (
                  <ListItem key={index} className="listItem">
                    <Chip
                      label={journey.name}
                      onClick={() => handleNavigateJourney(journey.id)}
                      onDelete={() => handleDeleteJourney(journey)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#1bb6ca",
                        color: "white",
                        width: "200px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <span>No journeys available</span>
                </ListItem>
              )
            ) : (
              <ListItem>
                <span>Please sign in to view your journeys</span>
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion className="sideBarAccordion">
        <AccordionSummary
          className="sideBarAccordionButton"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            <Bookmark /> Dream Lists
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className="listItem">
              <Chip
                icon={<AddCircleOutline style={{ color: "white" }} />}
                label="Create New List"
                onClick={handleCreateDreamList}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#1bb6ca",
                  color: "white",
                  width: "200px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  marginBottom: "10px",
                }}
              />
            </ListItem>
            {user ? (
              dreamLists && dreamLists.length > 0 ? (
                dreamLists.map((list, index) => (
                  <ListItem key={index} className="listItem">
                    <Chip
                      label={list.name}
                      onClick={() => handleNavigateDreamList(list.id)}
                      onDelete={() => handleDelete(list)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#1bb6ca",
                        color: "white",
                        width: "200px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <span>No dream lists available</span>
                </ListItem>
              )
            ) : (
              <ListItem>
                <span>Please sign in to view your dream lists</span>
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
      <Button
        variant="contained"
        startIcon={<LocationOnIcon />}
        sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
        className="sideBarButton"
        onClick={handleCheckInOpen}
      >
        Check-In
      </Button>
      <CheckIn
        open={openCheckIn}
        onClose={handleCheckInClose}
        refreshUser={refreshUser}
      />
      <AddMenu setRefresh={() => {}} />
    </div>
  );
};

export default Sidebar;
