import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, GeoPoint } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBrfnS8Ikd8isi1_LLG8KIPaaGMRvKun4I",
  authDomain: "kaleido-travel-vlogs.firebaseapp.com",
  projectId: "kaleido-travel-vlogs",
  storageBucket: "kaleido-travel-vlogs.appspot.com",
  messagingSenderId: "377562089239",
  appId: "1:377562089239:web:fcc1e67aa4ea64178ad952",
  measurementId: "G-SFWJ7CC0QE",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, storage, GeoPoint };
export default app;
