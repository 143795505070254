import React, { useState } from "react";
import { Fab, Menu, MenuItem, Popover } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const FabMenu = ({ onMenuItemClick }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    onMenuItemClick(item);
    handleMenuClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <div className="fabMenu">
      <Fab
        aria-label="add"
        onClick={handleMenuClick}
        sx={{
          color: "#1bb6ca",
          backgroundColor: "white",
        }}
      >
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("experience")}>
          New Experience
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("journey")}>
          New Journey
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("checkIn")}>
          Check-In
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FabMenu;
