import React, { useEffect, useRef } from "react";

const Map = ({ items }) => {
  const mapRef = useRef(null);

  const mapStyle = [
    {
      stylers: [
        {
          hue: "#2c3e50",
        },
        {
          saturation: 250,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 50,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  useEffect(() => {
    const { google } = window;
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: 0, lng: 0 },
      zoom: 2,
      styles: mapStyle, // Apply the custom style
    });

    if (!items || !items.itinerary) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    const pathCoordinates = [];

    const days = items.itinerary.days;
    let previousPosition = null;

    days.forEach((day, index) => {
      if (day.type !== "Sea Day" && day.geopoint) {
        const position = {
          lat: day.geopoint.latitude,
          lng: day.geopoint.longitude,
        };

        let labelText = `Day ${day.day}`;
        let titleText = `Day ${day.day}: ${day.location}`;

        new google.maps.Marker({
          position,
          map,
          label: {
            text: labelText,
            color: "black",
            fontSize: "12px",
            fontWeight: "bold",
          },
          title: titleText,
        });
        bounds.extend(position);
        pathCoordinates.push(position);
        previousPosition = position;
      } else if (day.type === "Sea Day" && previousPosition) {
        const nextDay = days
          .slice(index + 1)
          .find((d) => d.type !== "Sea Day" && d.geopoint);
        if (nextDay) {
          const nextPosition = {
            lat: nextDay.geopoint.latitude,
            lng: nextDay.geopoint.longitude,
          };
          const midPosition = {
            lat: (previousPosition.lat + nextPosition.lat) / 2,
            lng: (previousPosition.lng + nextPosition.lng) / 2,
          };
          new google.maps.Marker({
            position: midPosition,
            map,
            label: {
              text: `Day ${day.day}`,
              color: "blue",
              fontSize: "12px",
              fontWeight: "bold",
            },
            title: `Day ${day.day}: At Sea`,
          });
          bounds.extend(midPosition);
          pathCoordinates.push(midPosition);
          previousPosition = midPosition; // Update previousPosition to midPosition
        }
      }
    });

    const cruisePath = new google.maps.Polyline({
      path: pathCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    cruisePath.setMap(map);
    map.fitBounds(bounds);

    // Zoom out a little after fitting bounds
    google.maps.event.addListenerOnce(map, "bounds_changed", () => {
      map.setZoom(map.getZoom() + -0.8);
    });
  }, [items]);

  return <div ref={mapRef} style={{ width: "100%", height: "500px" }} />;
};

export default Map;
