/* global google */
import React, { useEffect, useState, useContext } from "react";
import algoliasearch from "algoliasearch/lite";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { format } from "date-fns";
import JourneyForm from "../../Components/items/newItem";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
  startAt,
  endAt,
  where,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Timestamp } from "firebase/firestore";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BeatLoader } from "react-spinners";
import ngeohash from "ngeohash";
import "../../Components/homeFeed/homeFeed.css";
import ReactCountryFlag from "react-country-flag";
import { set } from "date-fns";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import HotelItem from "../../Components/items/hotelItem";
import DestinationItem from "../../Components/items/destinationItem";
import ActivityItem from "../../Components/items/activityItem";
import FoodItem from "../../Components/items/foodItem";
import ThemeParkItem from "../../Components/items/themeParkItem";
import NewTraveller from "./newTraveller";
import ItineraryForm from "../../Components/items/newJourneyItinerary";
import UserItem from "../../Components/items/userItem";
import CheckInCard from "../../Components/items/CheckInCard";
import UserContext from "../../UserContext";
import Swal from "sweetalert2";
import "./JourneyPage.css";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";

const Journey = ({
  userId,
  onItemUpdate,
  setItems,
  items,
  refresh,
  setRefresh,
  journey,
}) => {
  const [filters, setFilters] = useState({
    location: [],
    categories: [],
  });
  const [loading, setLoading] = useState(true);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [open, setOpen] = useState(false);
  const [openItinerary, setOpenItinerary] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const handleClose = () => setOpenTravellers(false);
  const handleItemClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleItineraryClose = () => setOpenItinerary(false);
  const [openTravellers, setOpenTravellers] = useState(false);
  const [openEditItinerary, setOpenEditItinerary] = useState(false);
  const [journeyName, setJourneyName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [aboutJourney, setAboutJourney] = useState("");
  const db = getFirestore();
  const user = useContext(UserContext);
  const [days, setDays] = useState(journey.itinerary?.days || []);
  const [currentDay, setCurrentDay] = useState({
    day: "",
    address: "",
    countryCode: "",
    country: "",
    isTransitDay: false,
    transitLocation: { address: "", countryCode: "", country: "" },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ experience: "", videoLink: "" }],
      // other form fields...
    },
  });
  console.log(user);

  useEffect(() => {
    fetchData();
  }, [refresh]); // Call fetchData whenever refresh changes

  // Initialize Algolia
  const client = algoliasearch(
    "1REB6WCMBC",
    "f95d9e3225c839f9b5fff98b732406db"
  );
  const index = client.initIndex("JourneyItems_sorted_by_startDate");

  const fetchData = async () => {
    setLoading(true);

    // Perform the search on the 'journeyItems' index
    const journeyItemsContent = await index.search("", {
      filters: `journey.id:${journey.id}`,
    });

    setItems(journeyItemsContent.hits);

    setLoading(false);
    setRefresh(false); // Reset refresh
  };

  const renderItem = (item) => {
    console.log("Item:", item);
    switch (item.category) {
      case "Hotel":
        return <HotelItem item={item} userId={userId} />;
      case "Destination":
        return <DestinationItem item={item} userId={userId} />;
      case "Activity":
        return <ActivityItem item={item} userId={userId} />;
      case "Food":
        return <FoodItem item={item} userId={userId} />;
      case "ThemePark":
        return <ThemeParkItem item={item} userId={userId} />;
      case "User":
        return <UserItem item={item} userId={userId} />;
      case "Check-In":
        return <CheckInCard checkInData={item} userId={userId} />;
      default:
        return null;
    }
  };

  const handleOpenEditItinerary = (day) => {
    setCurrentDay(day);
    setOpenEditItinerary(true);
  };

  const handleCloseEditItinerary = () => setOpenEditItinerary(false);

  const handleEditItinerarySubmit = async (event) => {
    event.preventDefault();

    try {
      // Update the itinerary data in your Firestore database
      const journeyDoc = doc(db, "Journeys", journey.id);
      await updateDoc(journeyDoc, {
        itinerary: {
          days: days.map((day) =>
            day.day === currentDay.day ? currentDay : day
          ),
        },
      });

      // Close the modal
      setOpenEditItinerary(false);

      // Wait for the modal to close before showing the Swal alert
      setTimeout(() => {
        Swal.fire({
          title: "Updated!",
          text: "Your itinerary has been updated.",
          icon: "success",
          zIndex: 2000,
        });
      }, 500);
    } catch (error) {
      // Close the modal
      setOpenEditItinerary(false);

      // Wait for the modal to close before showing the Swal alert
      setTimeout(() => {
        Swal.fire({
          title: "Error!",
          text: "There was a problem updating your itinerary.",
          icon: "error",
          zIndex: 2000,
        });
      }, 500);
    }
  };

  // Add a new function to handle the selection of a username
  const handleSelectUsername = (event, newValue) => {
    const userName = newValue.userName;
    setSelectedUsernames((prevUsernames) => [...prevUsernames, userName]);
    setFilters((prevFilters) => {
      const newUserFilters = prevFilters.userName
        ? [...prevFilters.userName, userName]
        : [userName];
      const newFilters = { ...prevFilters, userName: newUserFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  function formatLocation(journey) {
    let location = journey.country;

    if (journey.state && journey.state !== "N/A") {
      location = `${journey.state}, ${location}`;
    }

    if (journey.city && journey.city !== "N/A") {
      location = `${journey.city}, ${location}`;
    }

    return location;
  }

  const handleOpenTravellers = () => {
    setOpenTravellers(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenItinerary = () => {
    setOpenItinerary(true);
  };

  // Function to handle opening the edit dialog
  const handleClickOpen = (journey) => {
    // Set the journeyName form field state variable to the journey data
    setJourneyName(journey.journeyName);
    setAboutJourney(journey.aboutJourney);

    // Check if the startDate and endDate values are defined
    if (journey.startDate && journey.endDate) {
      // Convert the Firestore Timestamps to JavaScript Date objects
      const startDateObj = journey.startDate.toDate();
      const endDateObj = journey.endDate.toDate();

      // Convert the date values to the format yyyy-MM-dd
      const startDateStr = startDateObj.toISOString().slice(0, 10);
      const endDateStr = endDateObj.toISOString().slice(0, 10);

      setStartDate(startDateStr);
      setEndDate(endDateStr);
    }

    setOpenEdit(true);
  };

  // Function to handle submitting the edit form
  const handleEditSubmit = async (journey, event) => {
    event.preventDefault();

    try {
      // Update the journey data in your Firestore database
      const journeyDoc = doc(db, "Journeys", journey.id);
      await updateDoc(journeyDoc, {
        journeyName: journeyName,
        startDate: startDate,
        endDate: endDate,
        aboutJourney: aboutJourney,
        // ...
      });

      // Close the modal
      setOpenEdit(false);

      // Wait for the modal to close before showing the Swal alert
      setTimeout(() => {
        Swal.fire({
          title: "Updated!",
          text: "Your journey has been updated.",
          icon: "success",
          zIndex: 2000,
        });
      }, 500);
    } catch (error) {
      // Close the modal
      setOpenEdit(false);

      // Wait for the modal to close before showing the Swal alert
      setTimeout(() => {
        Swal.fire({
          title: "Error!",
          text: "There was a problem updating your journey.",
          icon: "error",
          zIndex: 2000,
        });
      }, 500);
    }
  };

  const handleDelete = async () => {
    const journeyDoc = doc(db, "Journeys", journey.id);

    try {
      // Delete the journey document
      await deleteDoc(journeyDoc);

      // Fetch the user document
      const userDoc = doc(db, "Users", userId); // Replace 'userId' with the actual user ID

      // Get the current journeys array
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      const currentJourneys = userData.journeys;

      // Filter out the journey to be deleted
      const updatedJourneys = currentJourneys.filter(
        (j) => j.id !== journey.id
      );

      // Update the journeys array in the user document
      await updateDoc(userDoc, { journeys: updatedJourneys });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Journey deleted successfully",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete journey",
      });
    }
  };

  const groupDaysByLocation = (days) => {
    const groupedDays = [];
    let currentGroup = null;

    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      if (day.isTransitDay) {
        if (currentGroup) {
          groupedDays.push(currentGroup);
          currentGroup = null;
        }
        groupedDays.push(day);
      } else {
        if (currentGroup && day.address === currentGroup.location) {
          currentGroup.endDay = day.day;
        } else {
          if (currentGroup) {
            groupedDays.push(currentGroup);
          }
          currentGroup = {
            startDay: day.day,
            endDay: day.day,
            location: day.address,
            countryCode: day.countryCode,
            country: day.country,
          };
        }
      }
    }
    if (currentGroup) {
      groupedDays.push(currentGroup);
    }
    return groupedDays;
  };

  const groupedDays = groupDaysByLocation(journey.itinerary?.days || []);

  const handleSelect = async (index, address, type) => {
    const newDays = [...days];
    newDays[index][type] = address;
    setDays(newDays);

    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      console.log("results:", results);

      // Use PlacesService to get detailed information about the place
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const commonData = {
            name: address,
            placeId,
            address,
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "N/A",
            state:
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.short_name || "N/A",
            country:
              addressComponents.find((component) =>
                component.types.includes("country")
              )?.long_name || "N/A",
            countryCode:
              addressComponents.find((component) =>
                component.types.includes("country")
              )?.short_name || "N/A",
            geopoint: {
              latitude: latLng.lat,
              longitude: latLng.lng,
            },
          };

          if (type === "location") {
            newDays[index] = {
              ...newDays[index],
              ...commonData,
            };
          } else if (type === "startLocation") {
            newDays[index] = {
              ...newDays[index],
              ...commonData,
              startLocation: address,
            };
          } else if (type === "transitLocation") {
            newDays[index] = {
              ...newDays[index],
              transitLocation: {
                ...commonData,
                name: address,
              },
            };
          }

          setDays(newDays);

          newDays[index] = {
            ...newDays[index],
            lat: latLng.lat,
            lng: latLng.lng,
            placeId: placeId,
          };
          setDays(newDays);
        } else {
          console.error("PlacesService failed due to: ", status);
        }
      });
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const onSubmit = async (data) => {
    const firestore = getFirestore(); // Initialize Firestore
    try {
      // Validate data before updating
      const validDays = days.map((day) => ({
        ...day,
        date: day.date || "", // Provide default value if undefined
        location: day.location || "", // Provide default value if undefined
        startLocation: day.startLocation || "", // Provide default value if undefined
        transitLocation: day.transitLocation || {
          address: "",
          name: "",
          placeId: "",
          city: "",
          state: "",
          country: "",
          countryCode: "",
          geopoint: { latitude: 0, longitude: 0 },
        }, // Provide default value if undefined
      }));

      const updateData = {
        days: validDays,
        createdAt: serverTimestamp(),
      };

      console.log("Update Data:", updateData); // Log the data to be updated

      const JourneyRef = doc(firestore, "Journeys", journey.id);
      await updateDoc(JourneyRef, {
        itinerary: updateData,
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Itinerary updated successfully.",
        customClass: {
          container: "swal",
        },
      }).then(() => {
        window.location.reload(); // Refresh the page
      });
      handleItineraryClose();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <>
      {loading ? (
        <BeatLoader color="#123abc" loading={loading} size={15} />
      ) : (
        <>
          <div
            style={{
              width: "30vw",
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {user && user.uid === journey.userId && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "95%",
                  }}
                >
                  <Chip
                    label="Edit"
                    className="userChip"
                    sx={{
                      color: "white",
                      backgroundColor: "#1bb6ca",
                      fontSize: "1.2rem",
                      zIndex: "0",
                      margin: "0 10px",
                    }}
                    onClick={() => handleClickOpen(journey)}
                  />
                  <Chip
                    label="x"
                    className="userChip"
                    sx={{
                      color: "white",
                      backgroundColor: "#1bb6ca",
                      fontSize: "1.2rem",
                      zIndex: "0",
                    }}
                    onClick={handleDelete}
                  />
                </div>
              </>
            )}
            <div className="filterBox">
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "15px",
                  marginTop: "15px",
                }}
              ></div>
              <div>
                <div className="journeyfilterTagLine">
                  <p className="journeyName">{journey.journeyName}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "90%",
                    }}
                  >
                    <p className="journeyLocation">
                      {" "}
                      in {formatLocation(journey)}
                    </p>
                    <ReactCountryFlag
                      countryCode={journey.countryCode}
                      svg
                      style={{
                        width: "10%",
                        height: "10%",
                        marginTop: "10px",
                        border: "1px solid #1bb6ca",
                        borderRadius: "5px",
                      }}
                      title={journey.country}
                    />
                  </div>
                  <p className="withTravellers">
                    {format(journey.startDate.toDate(), "MMM dd, yyyy")} -{" "}
                    {format(journey.endDate.toDate(), "MMM dd, yyyy")}
                  </p>
                  <p className="withTravellers">
                    with {journey.userName}
                    {journey.travellers && journey.travellers.length > 0
                      ? journey.travellers
                          .map(
                            (traveller, index, array) =>
                              `${index === array.length - 1 ? " and" : ","} ${
                                traveller.userName
                              }`
                          )
                          .join("")
                      : ""}
                  </p>
                </div>
              </div>
              {user && user.uid === journey.userId && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleOpenTravellers}
                      sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                    >
                      Add Travellers
                    </Button>
                    <Modal
                      open={openTravellers}
                      onClose={handleClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        overflow: "auto",
                      }}
                    >
                      <NewTraveller
                        user={user ? user : null}
                        onClose={handleClose}
                        setRefresh={setRefresh}
                        journey={journey}
                      />
                    </Modal>
                    <Button
                      variant="contained"
                      onClick={handleOpen}
                      sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                    >
                      Add Experience
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleItemClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        overflow: "auto",
                      }}
                    >
                      <JourneyForm
                        user={user ? user : null}
                        onClose={handleItemClose}
                        setRefresh={setRefresh}
                        journey={journey}
                      />
                    </Modal>
                    {!journey.itinerary && (
                      <Button
                        variant="contained"
                        onClick={handleOpenItinerary}
                        sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                      >
                        Add Itinerary
                      </Button>
                    )}
                    <Modal
                      open={openItinerary}
                      onClose={handleItineraryClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        overflow: "auto",
                      }}
                    >
                      <ItineraryForm
                        user={user ? user : null}
                        onClose={handleItineraryClose}
                        setRefresh={setRefresh}
                        journey={journey}
                      />
                    </Modal>
                  </div>
                </>
              )}
              <hr />
              <p className="aboutJourney">{journey.aboutJourney}</p>
              {journey.itinerary &&
              journey.itinerary.days &&
              journey.itinerary.days.length > 0 ? (
                <div style={{ width: "100%" }}>
                  {isEditing ? null : (
                    <>
                      <hr />
                      <div className="itemItinerary">
                        <h2
                          className="itemName"
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        >
                          Itinerary
                        </h2>
                        {groupedDays.map((group, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginBottom: "10px",
                            }}
                          >
                            {group.isTransitDay ? (
                              <>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    width: "90px",
                                  }}
                                >
                                  Day {group.day}:
                                </p>
                                <span>
                                  <strong>
                                    {group.startLocation} to{" "}
                                    {group.transitLocation.address}
                                  </strong>
                                </span>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #1bb6ca",
                                    margin: "10px 5px",
                                  }}
                                >
                                  <ReactCountryFlag
                                    countryCode={
                                      group.transitLocation.countryCode
                                    }
                                    svg
                                    style={{ width: "3em", height: "auto" }}
                                    title={group.transitLocation.country}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    width: "90px",
                                  }}
                                >
                                  Days {group.startDay}-{group.endDay}:
                                </p>
                                <span>
                                  <strong>{group.location}</strong>
                                </span>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #1bb6ca",
                                    margin: "10px 5px",
                                  }}
                                >
                                  <ReactCountryFlag
                                    countryCode={group.countryCode}
                                    svg
                                    style={{ width: "3em", height: "auto" }}
                                    title={group.country}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      {journey.itinerary &&
                        journey.itinerary.days.length > 0 && (
                          <div>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#1bb6ca",
                                margin: "10px",
                              }}
                              onClick={handleOpenEditItinerary}
                            >
                              Edit Itinerary
                            </Button>
                          </div>
                        )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <h2 className="itemHeader">Itinerary</h2>
                  <div className="itemItinerary">
                    <p>No itinerary added yet</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginTop: "2em",
            height: "100vh",
          }}
        >
          <BeatLoader color="#123abc" loading={loading} size={15} />
        </div>
      ) : items && items.length > 0 ? (
        <div
          className="timeline-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {items.map((item) => renderItem(item))}
        </div>
      ) : (
        <div>
          <p
            style={{
              marginTop: "20px",
              marginBottom: "100px",
              color: "#415063",
            }}
          >
            Journey starting soon! Follow along to see the experiences shared
          </p>
        </div>
      )}

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="form-container">
          <div className="form">
            <form onSubmit={(event) => handleEditSubmit(journey, event)}>
              <div className="formHeading">
                <h1 className="headingdark">Edit your </h1>
                <h1 className="headingLight">Journey</h1>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="journeyName"
                    name="journeyName"
                    label="Journey Name"
                    value={journeyName}
                    fullWidth
                    {...register("journeyName")}
                    onChange={(e) => setJourneyName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="aboutJourney"
                    name="aboutJourney"
                    label="About Journey"
                    value={aboutJourney}
                    fullWidth
                    {...register("aboutJourney")}
                    onChange={(e) => setAboutJourney(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    fullWidth
                    {...register("startDate")}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    type="date"
                    value={endDate}
                    fullWidth
                    {...register("endDate")}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button className="submitButton" type="submit">
                  Submit
                </Button>
                <Button
                  className="cancelButton"
                  type="button"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        open={openEditItinerary}
        onClose={() => setOpenEditItinerary(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="form-container"
          style={{ maxHeight: "90vh", overflowY: "auto" }}
        >
          <div
            className="form"
            style={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ marginLeft: "20px" }}
            >
              <div className="formHeading">
                <h1 className="headingdark">Edit your </h1>
                <h1 className="headingLight">Itinerary</h1>
              </div>
              <Grid
                className="formControl"
                container
                direction="column"
                spacing={2}
              >
                {days.map((day, index) => (
                  <Grid item key={index}>
                    <InputLabel className="inputLabel">
                      Day {day.day}
                    </InputLabel>
                    <Controller
                      name={`days[${index}].date`}
                      control={control}
                      defaultValue={day.date}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            const newDays = [...days];
                            newDays[index].date = e.target.value;
                            setDays(newDays);
                          }}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.days && errors.days[index]?.date?.message}
                    </FormHelperText>
                    <Controller
                      name={`days[${index}].isTransitDay`}
                      control={control}
                      defaultValue={day.isTransitDay || false}
                      render={({ field }) => (
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Are you travelling to a new destination on this day?
                          </FormLabel>
                          <RadioGroup
                            {...field}
                            row
                            onChange={(e) => {
                              field.onChange(e);
                              const newDays = [...days];
                              newDays[index].isTransitDay =
                                e.target.value === "yes";
                              setDays(newDays);
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    <FormHelperText error>
                      {errors.days && errors.days[index]?.isTransitDay?.message}
                    </FormHelperText>
                    {day.isTransitDay ? (
                      <>
                        <PlacesAutocomplete
                          value={day.startLocation}
                          onChange={(address) => {
                            const newDays = [...days];
                            newDays[index].startLocation = address;
                            setDays(newDays);
                          }}
                          onSelect={(address) =>
                            handleSelect(index, address, "startLocation")
                          }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <TextField
                                {...getInputProps({
                                  label: "Where did you start on this day?",
                                  fullWidth: true,
                                  sx: { marginTop: "10px" },
                                })}
                              />
                              <div>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#fafafa"
                                      : "#ffffff",
                                    cursor: "pointer",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <PlacesAutocomplete
                          value={
                            day.transitLocation
                              ? day.transitLocation.address
                              : ""
                          }
                          onChange={(address) => {
                            const newDays = [...days];
                            newDays[index].transitLocation = { address };
                            setDays(newDays);
                          }}
                          onSelect={(address) =>
                            handleSelect(index, address, "transitLocation")
                          }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <TextField
                                {...getInputProps({
                                  label: "Where are you travelling to?",
                                  fullWidth: true,
                                  sx: { marginTop: "10px" },
                                })}
                              />
                              <div>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#fafafa"
                                      : "#ffffff",
                                    cursor: "pointer",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </>
                    ) : (
                      <>
                        <PlacesAutocomplete
                          value={day.location}
                          onChange={(address) => {
                            const newDays = [...days];
                            newDays[index].location = address;
                            setDays(newDays);
                          }}
                          onSelect={(address) =>
                            handleSelect(index, address, "location")
                          }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <TextField
                                {...getInputProps({
                                  label: "Where will you be this day?",
                                  fullWidth: true,
                                  sx: { marginTop: "10px" },
                                })}
                              />
                              <div>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#fafafa"
                                      : "#ffffff",
                                    cursor: "pointer",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setDays([
                        ...days,
                        {
                          day: days.length + 1,
                          date: "",
                          location: "",
                          isTransitDay: false,
                          startLocation: "",
                          transitLocation: { address: "" },
                        },
                      ])
                    }
                  >
                    + Add Day
                  </Button>
                </Grid>
                <hr />
                <Grid item>
                  <div className="formButtons">
                    <button className="submitButton" type="submit">
                      Submit
                    </button>
                    <button
                      className="cancelButton"
                      type="button"
                      onClick={handleItineraryClose}
                    >
                      Cancel
                    </button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Journey;
