import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import Journey from "./Journey.js";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Map from "./journeyItineraryMap.js";
import Modal from "@mui/material/Modal";
import { BeatLoader } from "react-spinners";
import Button from "@mui/material/Button";
import JourneyForm from "../../Components/items/newItem.js";
import UserContext from "../../UserContext";
import { css } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/sideBar.js";
import Swal from "sweetalert2";
import RightSidebar from "../../Components/Sidebar/rightSideBar.js";
import "./JourneyPage.css";

function JourneyPage() {
  const { id } = useParams();
  const [journey, setJourney] = useState(null);
  const [journeyItems, setJourneyItems] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const isSignedIn = user !== null;
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchJourney = async () => {
      setLoading(true);
      const journeyDoc = doc(db, "Journeys", id);
      const journeyData = await getDoc(journeyDoc);

      if (journeyData.exists()) {
        setJourney({ id: journeyData.id, ...journeyData.data() }); // Include the document ID in the state
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    fetchJourney();
  }, [id]);

  const handleDeleteList = async (listId) => {
    try {
      // Delete the document from the DreamLists collection
      const listRef = doc(db, "DreamLists", listId);
      await deleteDoc(listRef);

      // Remove the reference from the user's document
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        dreamLists: arrayRemove(listId),
      });

      // Update the state after deletion
      setDreamLists((prevLists) =>
        prevLists.filter((list) => list.id !== listId)
      );
    } catch (error) {
      console.error("Error deleting dream list:", error);
      Swal.fire("Error", "Failed to delete the dream list.", "error");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <Map items={journey} />
      <div className="fullWidthHome">
        <Sidebar
          dreamLists={dreamLists}
          journeys={journeys}
          onDelete={handleDeleteList}
          setDreamLists={setDreamLists}
          user={user}
        />
        <div className="homePageContainer">
          <header className="App-header">
            <div className="tagLine">
              <p className="homeText">Kaleido</p>
              <p className="highlight"> Travel</p>
            </div>
            <div>
              <p className="heroP">
                Your interactive diary for sharing your journeys and inspiring
                perfect travel experiences for our community.
              </p>
              <div>
                <Button
                  variant="contained"
                  component={Link}
                  to="/"
                  sx={{ backgroundColor: "#1bb6ca" }}
                >
                  Back to Home
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <JourneyForm
                    user={user ? user : null}
                    onClose={handleClose}
                    setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
                  />
                </Modal>
              </div>
            </div>

            {loading ? (
              <div style={{ marginTop: "20px" }}>
                <BeatLoader color="#123abc" loading={loading} size={15} />
              </div>
            ) : (
              <Journey
                userId={user ? user.uid : null}
                setItems={setItems}
                items={items}
                refresh={refresh} // This will be used to refresh the feed after a new item is added
                setRefresh={setRefresh}
                journey={journey}
              />
            )}
          </header>
        </div>
        <Footer />
        <RightSidebar />
      </div>
    </div>
  );
}

export default JourneyPage;
