import React, { useContext } from "react";
import UserContext from "../../UserContext";
import ReactCountryFlag from "react-country-flag";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import "./sideBar.css";
import LinearProgress from "@mui/material/LinearProgress";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import HotelIcon from "@mui/icons-material/Hotel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const RightSidebar = () => {
  const user = useContext(UserContext);

  return (
    <div className="sideBarRight">
      <div className="sideBarItem">
        {user ? (
          <div>
            <div className="rightSideBarSection">
              <h2 className="rightSideBarHeading">{user.userName}</h2>
              {user.rank ? <p>{user.rank}</p> : null}
              {user.stats && user.stats.points ? (
                <p>{user.stats.points}</p>
              ) : null}
            </div>
            {user.CheckIn ? (
              <div className="rightSideBarSection">
                <h2 className="rightSideBarStatus">Current Location:</h2>
                {user.CheckIn.country ? (
                  <>
                    <ReactCountryFlag
                      countryCode={user.CheckIn.countryCode}
                      svg
                      style={{
                        width: "3em",
                        height: "auto",
                      }}
                      title={user.CheckIn.country}
                    />
                  </>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    {user.CheckIn.name ? (
                      <>
                        <p>
                          <span className="blinking-dot"></span>{" "}
                          {user.CheckIn.name}
                        </p>{" "}
                      </>
                    ) : null}
                    {user.CheckIn.address ? (
                      <>
                        <p>{user.CheckIn.address}</p>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="rightSideBarSection">
              {user.stats &&
              user.stats.locations &&
              Object.keys(user.stats.locations).length > 0 ? (
                <>
                  <h2 className="rightSideBarSubHeading">Visited Locations</h2>
                  {Object.keys(user.stats.locations).map((country) => {
                    const countryCode =
                      user.stats.locations[country].countryCode;
                    return (
                      <div
                        key={country}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #1bb6ca",
                          marginRight: "5px",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={countryCode}
                          svg
                          style={{
                            width: "3em",
                            height: "auto",
                          }}
                          title={country}
                        />
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
            {user.stats ? (
              <>
                <div className="rightSideBarSection">
                  <h2 className="rightSideBarHeading">Category Stats</h2>
                  {user.stats && user.stats.Food ? (
                    <>
                      <div className="SideBarCategoryHeading">
                        <h2 className="rightSideBarSubHeading">Food</h2>
                        <FastfoodIcon
                          className="itemIcon"
                          style={{ color: "white" }}
                        />
                      </div>
                      <p>Points: {user.stats.Food.points}</p>
                      <p>Rank: {user.stats.Food.rank}</p>
                      <LinearProgress
                        variant="determinate"
                        value={(user.stats.Food.points % 50) * 2}
                      />
                    </>
                  ) : (
                    <p>No foods yet</p>
                  )}
                </div>
                <div className="rightSideBarSection">
                  {user.stats && user.stats.ThemePark ? (
                    <>
                      <div className="SideBarCategoryHeading">
                        <h2 className="rightSideBarSubHeading">Theme Park</h2>
                        <LocalActivityIcon
                          className="itemIcon"
                          style={{ color: "white" }}
                        />
                      </div>
                      <p>Points: {user.stats.ThemePark.points}</p>
                      <p>Rank: {user.stats.ThemePark.rank}</p>
                      <LinearProgress
                        variant="determinate"
                        value={(user.stats.ThemePark.points % 50) * 2}
                      />
                    </>
                  ) : (
                    <p>No Theme Parks yet</p>
                  )}
                </div>
                <div className="rightSideBarSection">
                  {user.stats && user.stats.ThemePark ? (
                    <>
                      <div className="SideBarCategoryHeading">
                        <h2 className="rightSideBarSubHeading">Hotel</h2>
                        <HotelIcon
                          className="itemIcon"
                          style={{ color: "white" }}
                        />
                      </div>
                      <p>Points: {user.stats.Hotel.points}</p>
                      <p>Rank: {user.stats.Hotel.rank}</p>
                      <LinearProgress
                        variant="determinate"
                        value={(user.stats.Hotel.points % 50) * 2}
                      />
                    </>
                  ) : (
                    <p>No Hotels yet</p>
                  )}
                </div>
                <div className="rightSideBarSection">
                  {user.stats && user.stats.ThemePark ? (
                    <>
                      <div className="SideBarCategoryHeading">
                        <h2 className="rightSideBarSubHeading">Activity</h2>
                        <CameraAltIcon
                          className="itemIcon"
                          style={{ color: "white" }}
                        />
                      </div>
                      <p>Points: {user.stats.Activity.points}</p>
                      <p>Rank: {user.stats.Activity.rank}</p>
                      <LinearProgress
                        variant="determinate"
                        value={(user.stats.Activity.points % 50) * 2}
                      />
                    </>
                  ) : (
                    <p>No Activities yet</p>
                  )}
                </div>
              </>
            ) : (
              <p>No stats available yet</p>
            )}
          </div>
        ) : (
          <div>
            <h2>Welcome</h2>
            <p>Sign in to access your personalised stats</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSidebar;
