import React, { useState, useContext } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import CruiseFeed from "../../Components/cruiseFeed/cruiseFeed.js";
import { db } from "../../firebase.js";
import Map from "../../Components/homeMap/homeMap";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import NewCruiseJourney from "../../Components/items/newCruise.js";
import UserContext from "../../UserContext";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Sidebar from "../../Components/Sidebar/sideBar.js";
import RightSidebar from "../../Components/Sidebar/rightSideBar.js";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import "./CruisePage.css";

function CruisePage() {
  const [journeyItems, setJourneyItems] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openJourney, setOpenJourney] = useState(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClosePopover2 = () => {
    setAnchorEl2(null);
  };

  const openPopover = Boolean(anchorEl);
  const openPopover2 = Boolean(anchorEl2);
  const id = openPopover ? "simple-popover" : undefined;
  const id2 = openPopover2 ? "simple-popover-2" : undefined;

  const handleOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleOpenJourney = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add a journey",
      });
    } else {
      setOpenJourney(true);
    }
  };

  const handleDeleteList = async (listId) => {
    try {
      // Delete the document from the DreamLists collection
      const listRef = doc(db, "DreamLists", listId);
      await deleteDoc(listRef);

      // Remove the reference from the user's document
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        dreamLists: arrayRemove(listId),
      });

      // Update the state after deletion
      setDreamLists((prevLists) =>
        prevLists.filter((list) => list.id !== listId)
      );
    } catch (error) {
      console.error("Error deleting dream list:", error);
      Swal.fire("Error", "Failed to delete the dream list.", "error");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <div className="fullWidthHome">
        <Sidebar
          dreamLists={dreamLists}
          journeys={journeys}
          onDelete={handleDeleteList}
          setDreamLists={setDreamLists}
          user={user}
        />
        <div className="homePageContainer">
          <header className="App-header" style={{ marginTop: "50px" }}>
            <div className="tagLine">
              <p className="homeText" style={{ marginRight: "5px" }}>
                Kaleido
              </p>
              <p className="highlight"> Cruise</p>
            </div>
            <div className="cruiseQuoteContainer">
              <p className="cruiseQuote">
                I wanted freedom, open air and adventure. I found it on the sea.
                - "Alaine Gerbault, Sailor"
              </p>
            </div>
            <div>
              <div className="homePageButtons">
                <Button
                  variant="contained"
                  onClick={handleOpenJourney}
                  sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                >
                  New Cruise
                </Button>
                <IconButton
                  aria-describedby={id2}
                  onClick={handleClick2}
                  sx={{ marginLeft: "-10px" }}
                >
                  <HelpOutlineIcon />
                </IconButton>
                <Popover
                  id={id2}
                  open={openPopover2}
                  anchorEl={anchorEl2}
                  onClose={handleClosePopover2}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    If you can't find your cruise, be the first to add it! Then
                    others can join and add their experiences to it.
                  </div>
                </Popover>
                <Modal
                  open={openJourney}
                  onClose={handleCloseJourney}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <NewCruiseJourney
                    user={user ? user : null}
                    onClose={handleCloseJourney}
                  />
                </Modal>
                <Modal
                  open={openJourney}
                  onClose={handleCloseJourney}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <NewCruiseJourney
                    user={user ? user : null}
                    onClose={handleCloseJourney}
                  />
                </Modal>
              </div>
            </div>
            <CruiseFeed
              userId={user ? user.uid : null}
              user={user ? user : null}
              setItems={setItems}
              items={items}
              refresh={refresh} // This will be used to refresh the feed after a new item is added
              setRefresh={setRefresh}
            />
          </header>
        </div>
        <RightSidebar />
        <Footer />
      </div>
    </div>
  );
}

export default CruisePage;
