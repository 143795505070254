import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import InstagramIcon from "@mui/icons-material/Instagram";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import ReactCountryFlag from "react-country-flag";
import { IconButton } from "@mui/material";
import { fromUnixTime, format } from "date-fns";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import * as htmlToImage from "html-to-image";
import domtoimage from "dom-to-image-more";
import html2canvas from "html2canvas";
import InstagramShareImage from "../Share/CruiseCountdown/InstagramShareImage";
import PreviewIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";
import download from "downloadjs";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Flip from "../Flip/Flip";
import ErrorBoundary from "../Flip/ErrorBoundary";
import { ClipLoader } from "react-spinners";
import "./item.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const calculateTimeLeft = (departureDate) => {
  const currentTime = new Date();
  const departureTime = new Date(departureDate);
  const timeDifference = departureTime - currentTime;

  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    )
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");
    return { days, hours, minutes, seconds };
  } else {
    return null;
  }
};

const CruiseJourneyItemCategory = ({ item, userId, onItemUpdate }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [isSharing, setIsSharing] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const hasRenderedRef = useRef(false);
  const containerRef = useRef();

  useEffect(() => {
    const fetchImageUrl = async () => {
      const storage = getStorage();
      const imageRef = ref(storage, `cruiseImages/${item.cruise.image}`);
      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image URL: ", error);
      }
    };

    fetchImageUrl();

    const updateCountdown = () => {
      const timeLeft = calculateTimeLeft(item.departureDate);
      setTimeLeft(timeLeft);
    };

    updateCountdown();

    // Update the countdown every second
    const timer = setInterval(() => {
      updateCountdown();
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, [item.cruise.image, item.departureDate]);

  // Function to capture the item and generate an image
  const handleShare = async () => {
    setIsSharing(true);
  };

  const handleRendered = useCallback(async (container) => {
    console.log("handleRendered called");
    if (hasRenderedRef.current) return;
    hasRenderedRef.current = true;
    setIsLoading(true);

    try {
      // Introduce a delay, then confirm all fonts are loaded
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await document.fonts.ready;

      // Wait for all images to load completely
      const images = container.getElementsByTagName("img");
      const imagePromises = Array.from(images).map((img) => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = reject;
          }
        });
      });

      await Promise.all(imagePromises);

      // Now generate the image using html2canvas
      const canvas = await html2canvas(container, {
        useCORS: true,
        scale: 1,
        width: 1080,
        height: 1350,
      });
      const dataUrl = canvas.toDataURL("image/jpeg", 1);
      setPreviewImage(dataUrl);
    } catch (error) {
      console.error("Error generating image:", error);
      Swal.fire("Oops!", "Error generating the image.", "error");
    } finally {
      setIsLoading(false);
      setIsSharing(false);
      hasRenderedRef.current = false;
    }
  }, []);
  useEffect(() => {
    if (isSharing) {
      handleRendered(containerRef.current);
    }
  }, [isSharing, handleRendered]);

  const handleCopyLink = () => {
    if (item && item.objectID) {
      const link = `www.kaleidotravelvlogs.com/Cruise/${item.objectID}`;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(link).then(
          () => {
            setIsAlertVisible(true);
            setTimeout(() => setIsAlertVisible(false), 3000); // Hide alert after 3 seconds
          },
          (err) => {
            console.error("Failed to copy link: ", err);
          }
        );
      } else {
        // Fallback method for copying text to clipboard
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          setIsAlertVisible(true);
          setTimeout(() => setIsAlertVisible(false), 3000); // Hide alert after 3 seconds
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textArea);
      }
    } else {
      console.error("Item object or objectID is undefined");
    }
  };

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const handlePreview = () => {
    setIsPreviewing(true);
    setIsSharing(true); // Trigger the handleRendered function
  };

  return (
    <>
      <div className="feedItem">
        <Link
          to={`/cruise/${item.objectID}`}
          style={{ textDecoration: "none" }}
        >
          <Paper elevation={24} className="hand-drawn-rectangle">
            <div className="topHalf">
              <div className="itemInfo">
                <DirectionsBoatIcon className="itemIcon" />
                <h2 className="itemHeader">Cruise</h2>
                <DirectionsBoatIcon className="itemIcon" />
              </div>
              <div className="itemTop">
                <img src={imageUrl} alt="cruise" className="itemImage" />
                <h2 className="itemName">{item.cruise.name}</h2>
                <p className="journeyItemLocaion">{item.ship.name}</p>
                <p
                  className="itemDate"
                  style={{ fontWeight: "bold", color: "#415063" }}
                >
                  Departing from {item.departurePort}
                </p>
                {item.departureDate && (
                  <p className="itemDate">
                    {format(
                      fromUnixTime(item.departureDate / 1000),
                      "do 'of' MMMM yyyy"
                    )}
                    {item.endDate &&
                      ` to ${format(
                        fromUnixTime(item.endDate / 1000),
                        "do 'of' MMMM yyyy"
                      )}`}
                  </p>
                )}
                <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {item.users
                    ? `${item.users.length} ${
                        item.users.length === 1 ? "cruiser" : "cruisers"
                      }`
                    : "0 cruisers"}
                </p>
              </div>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1bb6ca",
                  width: "auto",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Join the Journey
              </Button>

              {/* Countdown Timer */}
              <div
                style={{
                  marginBottom: "1em",
                  marginTop: "2em",
                  width: "95%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div>
                  <ErrorBoundary>
                    {timeLeft ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ textAlign: "center", margin: "0 5px" }}>
                          <Flip key="days" value={timeLeft.days} />
                          <div style={{ fontSize: "1rem" }}>days</div>
                        </div>
                        <div style={{ textAlign: "center", margin: "0 5px" }}>
                          <Flip key="hours" value={timeLeft.hours} />
                          <div style={{ fontSize: "1rem" }}>hours</div>
                        </div>
                        <div style={{ textAlign: "center", margin: "0 5px" }}>
                          <Flip key="minutes" value={timeLeft.minutes} />
                          <div style={{ fontSize: "1rem" }}>mins</div>
                        </div>
                        <div style={{ textAlign: "center", margin: "0 5px" }}>
                          <Flip key="seconds" value={timeLeft.seconds} />
                          <div style={{ fontSize: "1rem" }}>secs</div>
                        </div>
                      </div>
                    ) : (
                      <Flip value="DEPARTED" />
                    )}
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </Paper>
        </Link>
        <div className="itemActions">
          <div className="itemButtons">
            <SpeedDial
              ariaLabel="Share"
              icon={<ShareIcon sx={{ color: "blue" }} />}
              direction="right"
              sx={{
                zIndex: "1 !important",
                "& .MuiSpeedDial-fab": {
                  backgroundColor: "white",
                  border: "4px solid #1bb6ca",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                },
              }}
            >
              <SpeedDialAction
                icon={<InstagramIcon sx={{ color: "white" }} />}
                tooltipTitle="Preview Countdown Image"
                onClick={handlePreview}
              />
              <SpeedDialAction
                icon={<LinkIcon />}
                tooltipTitle="Copy Link"
                onClick={handleCopyLink}
              />
            </SpeedDial>
          </div>
        </div>
      </div>
      {/* Portal for Instagram share image */}
      {isSharing &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "1080px",
              height: "1350px",
            }}
          >
            <InstagramShareImage
              item={item}
              imageUrl={imageUrl}
              timeLeft={timeLeft}
              ref={containerRef}
            />
          </div>,
          document.body
        )}
      {/* Modal for Instagram share image preview */}
      <Modal
        open={isPreviewing}
        onClose={() => setIsPreviewing(false)}
        aria-labelledby="preview-modal-title"
        aria-describedby="preview-modal-description"
      >
        <div className="previewModal">
          <button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={() => setIsPreviewing(false)}
          >
            <Button variant="contained">Close</Button>
          </button>
          {/* Wrapper div to control scaling */}
          <div
            style={{
              width: "100%",
              height: "auto",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* Display the generated preview image */}
            {previewImage && (
              <div>
                <img
                  src={previewImage}
                  alt="Instagram Share Preview"
                  style={{ width: "100%", height: "auto" }}
                />
                {!isMobile && (
                  <a
                    href={previewImage}
                    download={`${item.cruise.name}-Kaleido.png`}
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    <Button variant="contained" startIcon={<DownloadIcon />}>
                      Download Image
                    </Button>
                  </a>
                )}
              </div>
            )}
          </div>
          {/* Loading spinner */}
          {isLoading && (
            <div className="loadingSpinner">
              <ClipLoader
                css={override}
                size={150}
                color={"#123abc"}
                loading={isLoading}
              />
              <h1 style={{ color: "black", margin: "15px" }}>
                Generating Image...
              </h1>
            </div>
          )}
        </div>
      </Modal>
      {/* Alert for link copied */}
      {isAlertVisible && (
        <Alert
          severity="success"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          Link copied to clipboard!
        </Alert>
      )}
    </>
  );
};

export default CruiseJourneyItemCategory;
