/* global google */
import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { geohashQueryBounds, distanceBetween } from "geofire-common";
import HotelIcon from "@mui/icons-material/Hotel";
import MapIcon from "@mui/icons-material/Map";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExploreIcon from "@mui/icons-material/Explore";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PersonIcon from "@mui/icons-material/Person";
import Autocomplete from "@mui/material/Autocomplete";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
  startAt,
  endAt,
  where,
} from "firebase/firestore";
import {
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
  Paper,
} from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BeatLoader } from "react-spinners";
import ReactCountryFlag from "react-country-flag";
import ngeohash from "ngeohash";
import "./homeFeed.css";
import { set } from "date-fns";
import HotelItem from "../items/hotelItem";
import DestinationItem from "../items/destinationItem";
import ActivityItem from "../items/activityItem";
import FoodItem from "../items/foodItem";
import ThemeParkItem from "../items/themeParkItem";
import UserItem from "../items/userItem";
import JourneyItemCategory from "../items/journeyItem";
import CheckInCard from "../items/CheckInCard";

const JourneyItem = ({
  userId,
  user,
  onItemUpdate,
  setItems,
  items,
  refresh,
  setRefresh,
}) => {
  const [address, setAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [filters, setFilters] = useState({
    location: [],
    categories: [],
  });
  const [coordinates, setCoordinates] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [hotel, setHotel] = useState("");
  const [themePark, setThemePark] = useState("");
  const [activity, setActivity] = useState("");
  const [food, setFood] = useState("");
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [selectedHotelFilters, setSelectedHotelFilters] = useState([]);
  const [selectedThemeParkFilters, setSelectedThemeParkFilters] = useState([]);
  const [selectedActivityFilters, setSelectedActivityFilters] = useState([]);
  const [selectedFoodFilters, setSelectedFoodFilters] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [geohashes, setGeohashes] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [selectedThemeParks, setSelectedThemeParks] = useState([]);
  const db = getFirestore();
  const colors = ["#FF6633"];

  useEffect(() => {
    fetchData();
  }, [refresh]); // Call fetchData whenever refresh changes

  const removeLocationFromFilter = (addressToRemove) => {
    // Find the index of the address to remove
    const index = selectedAddress.findIndex(
      (addressObj) => addressObj.address === addressToRemove
    );

    if (index !== -1) {
      // Remove the address from selectedAddress
      setSelectedAddress((prevAddresses) =>
        prevAddresses.filter(
          (addressObj) => addressObj.address !== addressToRemove
        )
      );

      // Remove the corresponding location from filters.location
      setFilters((prevFilters) => {
        const newLocations = [...prevFilters.location];
        newLocations.splice(index, 1);
        const newFilters = { ...prevFilters, location: newLocations };
        fetchData(newFilters); // Fetch data with the new filters
        return newFilters;
      });

      // Fetch the data with the new filters
      fetchData();
    }
  };

  const removeCategoryFromFilter = (categoryToRemove) => {
    // Find the index of the category to remove
    const index = selectedCategories.indexOf(categoryToRemove);

    if (index !== -1) {
      // Remove the category from selectedCategories
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((category) => category !== categoryToRemove)
      );

      // Remove the corresponding category from filters.categories
      const newCategories = filters.categories.filter(
        (category) => category !== categoryToRemove
      );
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, categories: newCategories };
        fetchData(newFilters); // Fetch data with the new filters
        return newFilters;
      });
    }
  };

  const handleDeleteHotelFilter = (hotelName) => () => {
    // Update the selectedHotelFilters state to remove the selected hotel
    setSelectedHotelFilters((prevFilters) =>
      prevFilters.filter((hotel) => hotel.name !== hotelName)
    );

    // Update the filters state to remove the selected hotel
    setFilters((prevFilters) => {
      const newHotelFilters = prevFilters.hotel
        ? prevFilters.hotel.filter((hotel) => hotel.name !== hotelName)
        : [];
      const newFilters = { ...prevFilters, hotel: newHotelFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  const handleDeleteThemeParkFilter = (themeParkName) => () => {
    // Update the selectedHotelFilters state to remove the selected hotel
    setSelectedThemeParkFilters((prevFilters) =>
      prevFilters.filter((themePark) => themePark.name !== themeParkName)
    );

    // Update the filters state to remove the selected hotel
    setFilters((prevFilters) => {
      const newThemeParkFilters = prevFilters.themePark
        ? prevFilters.themePark.filter(
            (themePark) => themePark.name !== themeParkName
          )
        : [];
      const newFilters = { ...prevFilters, themePark: newThemeParkFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  const handleDeleteActivityFilter = (activityName) => () => {
    // Update the selectedHotelFilters state to remove the selected hotel
    setSelectedActivityFilters((prevFilters) =>
      prevFilters.filter((activity) => activity.name !== activityName)
    );

    // Update the filters state to remove the selected hotel
    setFilters((prevFilters) => {
      const newActivityFilters = prevFilters.activity
        ? prevFilters.activity.filter(
            (activity) => activity.name !== activityName
          )
        : [];
      const newFilters = { ...prevFilters, activity: newActivityFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  const handleDeleteFoodFilter = (foodName) => () => {
    // Update the selectedHotelFilters state to remove the selected hotel
    setSelectedFoodFilters((prevFilters) =>
      prevFilters.filter((food) => food.name !== foodName)
    );

    // Update the filters state to remove the selected hotel
    setFilters((prevFilters) => {
      const newFoodFilters = prevFilters.food
        ? prevFilters.food.filter((food) => food.name !== foodName)
        : [];
      const newFilters = { ...prevFilters, food: newFoodFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  const removeUsernameFromFilter = (usernameToRemove) => {
    // Find the index of the username to remove
    const index = selectedUsernames.indexOf(usernameToRemove);

    if (index !== -1) {
      // Remove the username from selectedUsernames
      setSelectedUsernames((prevUsernames) =>
        prevUsernames.filter((username) => username !== usernameToRemove)
      );

      // Remove the corresponding username from filters.userName
      const newUsernames = filters.userName.filter(
        (username) => username !== usernameToRemove
      );
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, userName: newUsernames };
        fetchData(newFilters); // Fetch data with the new filters
        return newFilters;
      });
    }
  };

  // Initialize Algolia
  const client = algoliasearch(
    "1REB6WCMBC",
    "f95d9e3225c839f9b5fff98b732406db"
  );
  const index = client.initIndex("JourneyItems");
  const usersIndex = client.initIndex("Users");
  const journeysIndex = client.initIndex("Journeys");

  const fetchData = async (filters) => {
    setLoading(true);

    let locationFilter = "";
    let aroundLatLng;
    let aroundRadius = 3000000; // Define the radius in meters
    if (filters && filters.location && filters.location.length > 0) {
      const location = filters.location[filters.location.length - 1];
      let filterParts = [];
      if (location.locality) filterParts.push(`city:"${location.locality}"`);
      if (location.administrative_area_level_1)
        filterParts.push(`state:"${location.administrative_area_level_1}"`);
      if (location.country) filterParts.push(`country:"${location.country}"`);
      locationFilter = filterParts.join(" AND ");
      console.log(filters);

      // Add the latitude and longitude to the search parameters
      if (location._geoloc) {
        aroundLatLng = `${location._geoloc.lat},${location._geoloc.lng}`;
      }
    }

    let categoryFilter = "";
    if (filters && filters.categories && filters.categories.length > 0) {
      categoryFilter = filters.categories
        .map((category) => `category:"${category}"`)
        .join(" OR ");
    }

    let hotelFilter = "";
    if (filters && filters.hotel && filters.hotel.length > 0) {
      hotelFilter = filters.hotel
        .map((hotel) => {
          return `name:"${hotel.name}"`;
        })
        .join(" OR ");
    }

    let themeParkFilter = "";
    if (filters && filters.themePark && filters.themePark.length > 0) {
      themeParkFilter = filters.themePark
        .map((themePark) => {
          return `name:"${themePark.name}"`;
        })
        .join(" OR ");
    }

    let activityFilter = "";
    if (filters && filters.activity && filters.activity.length > 0) {
      activityFilter = filters.activity
        .map((activity) => {
          return `name:"${activity.name}"`;
        })
        .join(" OR ");
    }

    let foodFilter = "";
    if (filters && filters.food && filters.food.length > 0) {
      foodFilter = filters.food
        .map((food) => {
          return `name:"${food.name}"`;
        })
        .join(" OR ");
    }

    let userFilter = "";
    if (filters && filters.users && filters.users.length > 0) {
      userFilter = filters.users
        .map((user) => {
          return `userName:"${user}"`;
        })
        .join(" OR ");
    }

    // Add userName to the filters in fetchData
    let userNameFilter = "";
    if (filters && filters.userName && filters.userName.length > 0) {
      userNameFilter = filters.userName
        .map((userName) => `userName:"${userName}"`)
        .join(" OR ");
    }

    let filtersArray = [];
    if (categoryFilter) filtersArray.push(`(${categoryFilter})`);
    if (locationFilter) filtersArray.push(`(${locationFilter})`);
    if (hotelFilter) filtersArray.push(`(${hotelFilter})`);
    if (themeParkFilter) filtersArray.push(`(${themeParkFilter})`);
    if (activityFilter) filtersArray.push(`(${activityFilter})`);
    if (foodFilter) filtersArray.push(`(${foodFilter})`);
    if (userNameFilter) filtersArray.push(`(${userNameFilter})`);

    let finalFilter = filtersArray.join(" AND ");

    const searchParameters = {
      filters: finalFilter,
    };

    if (aroundLatLng) {
      searchParameters.aroundLatLng = aroundLatLng;
      searchParameters.aroundRadius = aroundRadius;
    }

    // Perform the search on the 'journeyItems' index
    const journeyItemsContent = await index.search("", searchParameters);

    let combinedHits = [...journeyItemsContent.hits];

    // Check if the category is 'user' before searching the 'Users' index
    if (filters && filters.categories && filters.categories.includes("User")) {
      // Perform the search on the 'Users' index with the same filters
      const usersContent = await usersIndex.search("", searchParameters);

      // Combine the hits from both indices
      combinedHits = [...combinedHits, ...usersContent.hits];
    }

    // Check if the category is 'journey' before searching the 'Journeys' index
    if (
      filters &&
      filters.categories &&
      filters.categories.includes("Journey")
    ) {
      // Perform the search on the 'Journeys' index with the same filters
      const journeysContent = await journeysIndex.search("", searchParameters);

      // Combine the hits from all indices
      console.log("Journeys content:", journeysContent);
      combinedHits = [...combinedHits, ...journeysContent.hits];
    }

    console.log(combinedHits);
    console.log("Filters:", filters);
    console.log("Search parameters:", searchParameters);
    setLoading(false);
    setItems(combinedHits);
    setRefresh(false); // Reset refresh
  };

  // Call fetchData in the handleSelect function
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);

    let city, state, country, countryCode;
    for (let i = 0; i < results[0].address_components.length; i++) {
      const component = results[0].address_components[i];

      if (
        component.types.includes("locality") ||
        component.types.includes("administrative_area_level_2")
      ) {
        city = component.long_name;
      }

      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }

      if (component.types.includes("country")) {
        country = component.long_name;
        countryCode = component.short_name;
      }
    }

    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        location: [
          { locality: city, administrative_area_level_1: state, country },
        ],
      };

      return newFilters;
    });

    // Replace the existing location with the new one
    setSelectedAddress([{ address: value, city, state, country, countryCode }]);
    setSelectedLocation({
      locality: city,
      administrative_area_level_1: state,
      country,
    });
  };

  // Call fetchData on component mount to load all items initially
  useEffect(() => {
    fetchData();
  }, []);

  // Custom hook for debouncing
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  // In your component
  const debouncedLocation = useDebounce(selectedLocation, 500);

  useEffect(() => {
    if (debouncedLocation) {
      fetchData({ ...filters, location: [debouncedLocation] });
    }
  }, [debouncedLocation, filters]);

  // When the user types a country or city name into the filter...
  const handleFilterChange = async (value) => {
    if (value.length >= 3) {
      try {
        const results = await geocodeByAddress(value);
        const fullPlaceName = results[0].formatted_address;

        let city, state, country;
        for (let i = 0; i < results[0].address_components.length; i++) {
          const component = results[0].address_components[i];

          if (
            component.types.includes("locality") ||
            component.types.includes("administrative_area_level_2")
          ) {
            city = component.short_name;
          }

          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }

          if (component.types.includes("country")) {
            country = component.long_name;
          }
        }

        setFilters((prevFilters) => ({
          ...prevFilters,
          location: [
            { locality: city, administrative_area_level_1: state, country },
          ],
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCategorySelect = async (event) => {
    const category = event.target.value;
    setSelectedCategories((prevCategories) => [...prevCategories, category]);

    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        categories: [...(prevFilters.categories || []), category],
      };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  const handleSelectHotel = async (description, placeId) => {
    // Add the selected hotel to the selectedHotels state
    setSelectedHotels((prevHotels) => [
      ...prevHotels,
      { id: placeId, hotelData: { name: description } },
    ]);

    // Update the filters state to include the selected hotel
    setFilters((prevFilters) => {
      const newHotelFilters = prevFilters.hotel
        ? [...prevFilters.hotel, { id: placeId, name: description }]
        : [{ id: placeId, name: description }];

      return { ...prevFilters, hotel: newHotelFilters };
    });
  };

  const renderItem = (item) => {
    console.log("Item:", item);
    switch (item.category) {
      case "Hotel":
        return <HotelItem item={item} userId={userId} />;
      case "Destination":
        return <DestinationItem item={item} userId={userId} />;
      case "Activity":
        return <ActivityItem item={item} userId={userId} />;
      case "Food":
        return <FoodItem item={item} userId={userId} />;
      case "ThemePark":
        return <ThemeParkItem item={item} userId={userId} />;
      case "User":
        return <UserItem item={item} userId={userId} />;
      case "Journey":
        return <JourneyItemCategory item={item} userId={userId} />;
        case "Check-In":
          return <CheckInCard checkInData={item} userId={userId} />;
      default:
        return null;
    }
  };

  const handleSelectHotelFilter = (description, placeId) => {
    const request = {
      placeId: placeId,
      fields: ["name"],
    };

    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const hotelName = place.name;

        // Update the selectedHotelFilters state to include the selected hotel
        setSelectedHotelFilters((prevFilters) => [
          ...prevFilters,
          { id: placeId, name: hotelName },
        ]);

        // Update the filters state to include the selected hotel
        setFilters((prevFilters) => {
          const newHotelFilters = prevFilters.hotel
            ? [...prevFilters.hotel, { id: placeId, name: hotelName }]
            : [{ id: placeId, name: hotelName }];

          return { ...prevFilters, hotel: newHotelFilters };
        });
      } else {
        console.error(
          "Error: No location data available for this place.",
          "Status:",
          status,
          "Place:",
          place
        );
      }
    });
  };

  const handleSelectThemeParkFilter = (description, placeId) => {
    const request = {
      placeId: placeId,
      fields: ["name"],
    };

    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const themeParkName = place.name;
        console.log("Theme park name:", themeParkName);

        // Update the selectedHotelFilters state to include the selected hotel
        setSelectedThemeParkFilters((prevFilters) => [
          ...prevFilters,
          { id: placeId, name: themeParkName },
        ]);

        // Update the filters state to include the selected hotel
        setFilters((prevFilters) => {
          const newThemeParkFilters = prevFilters.themePark
            ? [...prevFilters.themePark, { id: placeId, name: themeParkName }]
            : [{ id: placeId, name: themeParkName }];

          const newFilters = { ...prevFilters, themePark: newThemeParkFilters };
          fetchData(newFilters); // Fetch data with the new filters
          return newFilters;
        });
      } else {
        console.error(
          "Error: No location data available for this place.",
          "Status:",
          status,
          "Place:",
          place
        );
      }
    });
  };

  const handleSelectActivityFilter = (description, placeId) => {
    const request = {
      placeId: placeId,
      fields: ["name"],
    };

    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const activityName = place.name;
        console.log("activity name:", activityName);

        // Update the selectedActivityFilters state to include the selected activity
        setSelectedActivityFilters((prevFilters) => [
          ...prevFilters,
          { id: placeId, name: activityName },
        ]);

        // Update the filters state to include the selected activity
        setFilters((prevFilters) => {
          const newActivityFilters = prevFilters.activity
            ? [...prevFilters.activity, { id: placeId, name: activityName }]
            : [{ id: placeId, name: activityName }];

          const newFilters = { ...prevFilters, activity: newActivityFilters };
          fetchData(newFilters); // Fetch data with the new filters
          return newFilters;
        });
      } else {
        console.error(
          "Error: No location data available for this place.",
          "Status:",
          status,
          "Place:",
          place
        );
      }
    });
  };

  const handleSelectFoodFilter = (description, placeId) => {
    const request = {
      placeId: placeId,
      fields: ["name"],
    };

    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const foodName = place.name;
        console.log("Food name:", foodName);

        // Update the selectedActivityFilters state to include the selected food
        setSelectedFoodFilters((prevFilters) => [
          ...prevFilters,
          { id: placeId, name: foodName },
        ]);

        // Update the filters state to include the selected food
        setFilters((prevFilters) => {
          const newFoodFilters = prevFilters.food
            ? [...prevFilters.food, { id: placeId, name: foodName }]
            : [{ id: placeId, name: foodName }];

          const newFilters = { ...prevFilters, food: newFoodFilters };
          fetchData(newFilters); // Fetch data with the new filters
          return newFilters;
        });
      } else {
        console.error(
          "Error: No location data available for this place.",
          "Status:",
          status,
          "Place:",
          place
        );
      }
    });
  };

  const handleSearch = async (event, newValue) => {
    const results = await index.search(newValue);
    setUserSearchResults(results.hits);
  };

  // Add a new function to handle the selection of a username
  const handleSelectUsername = (event, newValue) => {
    const userName = newValue.userName;
    setSelectedUsernames((prevUsernames) => [...prevUsernames, userName]);
    setFilters((prevFilters) => {
      const newUserFilters = prevFilters.userName
        ? [...prevFilters.userName, userName]
        : [userName];
      const newFilters = { ...prevFilters, userName: newUserFilters };
      fetchData(newFilters); // Fetch data with the new filters
      return newFilters;
    });
  };

  return (
    <>
      <div className="filterBox">
        <div className="filterTagLine">
          <p className="filterText">Find your next</p>
          <p className="filterHighlight">Adventure</p>
        </div>
        <div className="filterItem">
          <div>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <div style={{ marginBottom: "10px" }}>
                  <PlacesAutocomplete
                    value={address}
                    onChange={(value) => {
                      setAddress(value); // Update address state as user types
                      handleFilterChange(value); // Normalize filter as user types
                    }}
                    onSelect={handleSelect}
                    className="common-input"
                    sx={{
                      fontFamily: "Poppins !important",
                      fontWeight: "bold",
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Filter by location ...",
                            className: "common-input",
                          })}
                          style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                        />
                        <div
                          className="autocomplete-dropdown-container"
                          style={{ position: "absolute", zIndex: 1000 }}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                              fontSize: "1rem",
                              color: "black",
                              cursor: "pointer",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <div style={{ textAlign: "start" }}>
                  <InputLabel
                    id="category-select-label"
                    className="common-input"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Filter by category
                  </InputLabel>
                  <Select
                    labelId="category-select-label"
                    value={selectedCategories[0] || ""}
                    onChange={handleCategorySelect}
                    className="common-input"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#415063",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                      },
                      "& .MuiSelect-select": {
                        textAlign: "center",
                      },
                    }}
                  >
                    <MenuItem
                      value="Hotel"
                      style={{ color: "#415063 !important" }}
                    >
                      Hotel
                    </MenuItem>
                    <MenuItem
                      value="Destination"
                      style={{ color: "#415063 !important" }}
                    >
                      Destination
                    </MenuItem>
                    <MenuItem
                      value="Activity"
                      style={{ color: "#415063 !important" }}
                    >
                      Activity
                    </MenuItem>
                    <MenuItem
                      value="Food"
                      style={{ color: "#415063 !important" }}
                    >
                      Food
                    </MenuItem>
                    <MenuItem
                      value="ThemePark"
                      style={{ color: "#415063 !important" }}
                    >
                      Theme Park
                    </MenuItem>
                    <MenuItem
                      value="Journey"
                      style={{ color: "#415063 !important" }}
                    >
                      Journey
                    </MenuItem>
                    <MenuItem
                      value="User"
                      style={{ color: "#415063 !important" }}
                    >
                      User
                    </MenuItem>
                    {/* Add more categories here as they become available */}
                  </Select>
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  options={userSearchResults}
                  getOptionLabel={(option) => option.userName}
                  onInputChange={handleSearch}
                  onChange={handleSelectUsername}
                  className="common-input"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by user"
                      InputLabelProps={{
                        style: { fontFamily: "Poppins", fontWeight: "bold" }, // Add this line
                      }}
                      InputProps={{
                        ...params.InputProps,
                        className: "common-input",
                        style: { textAlign: "center" },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {selectedCategories.includes("Hotel") && (
                <div style={{ marginBottom: "10px" }}>
                  <FormControl fullWidth>
                    <PlacesAutocomplete
                      value={hotel}
                      onChange={setHotel}
                      searchOptions={{
                        types: ["lodging"],
                      }}
                      onSelect={handleSelectHotelFilter}
                      // other props...
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search for a hotel...",
                              className: "common-input",
                              style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                                fontSize: "1rem",
                                color: "black",
                                cursor: "pointer",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormControl>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {selectedCategories.includes("ThemePark") && (
                <div style={{ marginBottom: "10px" }}>
                  <FormControl fullWidth>
                    <PlacesAutocomplete
                      value={themePark}
                      onChange={setThemePark}
                      searchOptions={{
                        types: ["amusement_park"],
                      }}
                      onSelect={handleSelectThemeParkFilter}
                      // other props...
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search for a theme park...",
                              className: "common-input",
                              style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                                fontSize: "1rem",
                                color: "black",
                                cursor: "pointer",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormControl>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {selectedCategories.includes("Activity") && (
                <div style={{ marginBottom: "10px" }}>
                  <FormControl fullWidth>
                    <PlacesAutocomplete
                      value={activity}
                      onChange={setActivity}
                      searchOptions={{
                        types: ["establishment"],
                      }}
                      onSelect={handleSelectActivityFilter}
                      // other props...
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search for an activity...",
                              className: "common-input",
                              style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                                fontSize: "1rem",
                                color: "black",
                                cursor: "pointer",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormControl>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {selectedCategories.includes("Food") && (
                <div style={{ marginBottom: "10px" }}>
                  <FormControl fullWidth>
                    <PlacesAutocomplete
                      value={food}
                      onChange={setFood}
                      searchOptions={{
                        types: ["restaurant", "cafe", "bakery", "bar"],
                      }}
                      onSelect={handleSelectFoodFilter}
                      // other props...
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search for a food...",
                              className: "common-input",
                              style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                                fontSize: "1rem",
                                color: "black",
                                cursor: "pointer",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormControl>
                </div>
              )}
            </Grid>
          </div>
          {/* Add more filter items here */}
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {selectedAddress.map((addressObj) => {
          const { address, countryCode } = addressObj;
          console.log(address, countryCode);
          return (
            <div
              key={address}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0.5em",
              }}
            >
              <Chip
                avatar={
                  <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    style={{
                      fontSize: "1.2em", // Adjust this to make the flag smaller or larger
                    }}
                  />
                }
                label={address}
                onDelete={() => removeLocationFromFilter(address)}
                style={{
                  margin: "0.5em",
                  backgroundColor: "#1bb6ca",
                  color: "#FFF",
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {selectedCategories &&
          selectedCategories.map((category, index) => (
            <Chip
              avatar={
                category === "Hotel" ? (
                  <HotelIcon style={{ color: "#415063" }} />
                ) : category === "Destination" ? (
                  <LocationOnIcon style={{ color: "#415063" }} />
                ) : category === "Activity" ? (
                  <CameraAltIcon style={{ color: "#415063" }} />
                ) : category === "Food" ? (
                  <FastfoodIcon style={{ color: "#415063" }} />
                ) : category === "ThemePark" ? (
                  <LocalActivityIcon style={{ color: "#415063" }} />
                ) : category === "Journey" ? (
                  <MapIcon style={{ color: "#415063" }} />
                ) : category === "User" ? (
                  <PersonIcon style={{ color: "#415063" }} />
                ) : null
              }
              key={index}
              label={category}
              onDelete={() => removeCategoryFromFilter(category)}
              style={{
                margin: "0.5em",
                backgroundColor: "#1bb6ca",
                color: "#FFF",
              }}
            />
          ))}
      </div>
      {selectedHotelFilters &&
        selectedHotelFilters.map((hotel) => (
          <Chip
            avatar={<HotelIcon style={{ color: "#415063" }} />}
            key={hotel.id}
            label={hotel.name}
            onDelete={handleDeleteHotelFilter(hotel.name)}
            style={{
              margin: "0.5em",
              backgroundColor: "#1bb6ca",
              color: "#FFF",
            }}
          />
        ))}
      {selectedUsernames &&
        selectedUsernames.map((userName, index) => (
          <Chip
            key={index}
            avatar={<PersonIcon style={{ color: "#415063" }} />}
            label={userName}
            onDelete={() => removeUsernameFromFilter(userName)}
            style={{
              margin: "0.5em",
              backgroundColor: "#1bb6ca",
              color: "#FFF",
            }}
          />
        ))}
      {selectedThemeParkFilters &&
        selectedThemeParkFilters.map((themePark, index) => (
          <Chip
            avatar={<LocalActivityIcon style={{ color: "#415063" }} />}
            key={themePark.id}
            label={themePark.name}
            onDelete={handleDeleteThemeParkFilter(themePark.name)}
            style={{
              margin: "0.5em",
              backgroundColor: "#1bb6ca",
              color: "#FFF",
            }}
          />
        ))}
      {selectedActivityFilters &&
        selectedActivityFilters.map((activity, index) => (
          <Chip
            avatar={<CameraAltIcon style={{ color: "#415063" }} />}
            key={activity.id}
            label={activity.name}
            onDelete={handleDeleteActivityFilter(activity.name)}
            style={{
              margin: "0.5em",
              backgroundColor: "#1bb6ca",
              color: "#FFF",
            }}
          />
        ))}
      {selectedFoodFilters &&
        selectedFoodFilters.map((food, index) => (
          <Chip
            avatar={<FastfoodIcon style={{ color: "#415063" }} />}
            key={food.id}
            label={food.name}
            onDelete={handleDeleteFoodFilter(food.name)}
            style={{
              margin: "0.5em",
              backgroundColor: "#1bb6ca",
              color: "#FFF",
            }}
          />
        ))}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginTop: "2em",
            height: "100vh",
          }}
        >
          <BeatLoader color="#123abc" loading={loading} size={15} />
        </div>
      ) : items && items.length > 0 ? (
        items.map((item) => renderItem(item))
      ) : (
        <div>
          <p
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              color: "#415063",
            }}
          >
            Be the first to add content for this location!
          </p>
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://discord.com/widget?id=1049492045015822366&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>`,
        }}
      />
    </>
  );
};

export default JourneyItem;
