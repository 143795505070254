/* global google */
import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, GeoPoint } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { geohashForLocation } from "geofire-common";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { geocodeByPlaceId } from "react-places-autocomplete";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import NewHotelItem from "./newHotelItem";
import NewDestinationItem from "./newDestinationItem";
import NewActivityItem from "./newActivityItem";
import NewFoodItem from "./newFoodItem";
import NewThemeParkItem from "./newThemeParkItem";
import "./newItem.css";

function NewJourney(props) {
  const { user, onClose, addNewItem, fetchData, setRefresh } = props;
  const [category, setCategory] = useState("");
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [loading, setLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const db = getFirestore();
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ experience: "", videoLink: "" }],
      // other form fields...
    },
  });

  const createNewJourney = async (journeyItem) => {
    console.log("Starting createNewJourneyItem function");

    setLoading(true);

    try {
      if (selectedDestination !== null) {
        console.log("selectedDestination is not null");

        // Get the destination data from the selectedDestination state
        const destinationData = selectedDestination;

        // Query the Destinations collection for a destination with the same name
        const q = query(
          collection(db, "Destinations"),
          where("name", "==", destinationData.name)
        );
        const querySnapshot = await getDocs(q);

        // Define destinationId before the if condition
        let destinationId;

        if (querySnapshot.empty) {
          console.log(
            "Destination does not exist in the Destinations collection"
          );

          let blob;

          // Get the place ID from the destination data
          const placeId = destinationData.placeId;

          if (typeof placeId === "string") {
            const functionUrl = `https://australia-southeast1-kaleido-travel-vlogs.cloudfunctions.net/getImage?placeId=${encodeURIComponent(
              placeId
            )}`;
            const response = await fetch(functionUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const buffer = await response.arrayBuffer();
            blob = new Blob([buffer], { type: "image/jpeg" });
          } else {
            console.error("placeId is not a string or is undefined:", placeId);
          }

          if (blob) {
            // Upload the image to Firebase Storage
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `destinationImages/${destinationData.name}`
            );
            const uploadSnapshot = await uploadBytes(storageRef, blob);
            console.log("Upload snapshot:", uploadSnapshot);

            // Get the download URL for the image
            const url = await getDownloadURL(storageRef);
            console.log("Download URL:", url);

            // Store the download URL in the destinationData
            destinationData.imageUrl = url;

            // Add the destination to the Destinations collection
            const docRef = await addDoc(
              collection(db, "Destinations"),
              destinationData
            );
            console.log("Added new destination with ID: ", docRef.id);
            // Assign the new destination's ID to destinationId
            destinationId = docRef.id;
          } else {
            console.log("Blob is not defined");
          }
        } else {
          // If the destination does exist, use its data
          console.log(
            "Found existing destination with data: ",
            destinationData
          );

          // Get the first document from the querySnapshot
          const doc = querySnapshot.docs[0];

          // Get the data from the document
          const existingDestinationData = doc.data();

          // Get the ID of the destination document
          destinationId = doc.id;

          // Use the existing destination's imageUrl
          destinationData.imageUrl = existingDestinationData.imageUrl;
        }

        // Add the destination data to the journeyItem
        journeyItem = { ...journeyItem, ...destinationData };

        // Calculate the geohash for the location
        const geohash = geohashForLocation([
          destinationData.geopoint.latitude,
          destinationData.geopoint.longitude,
        ]);

        // Add the geohash to the journeyItem
        journeyItem.geohash = geohash;

        // Add the geopoint to the journeyItem
        journeyItem.geopoint = destinationData.geopoint;

        // Add a createdAt field with the current timestamp
        journeyItem.createdAt = serverTimestamp();

        // Convert the startDate and endDate strings to Timestamps
        if (journeyItem.startDate) {
          journeyItem.startDate = Timestamp.fromDate(
            new Date(journeyItem.startDate)
          );
        }

        if (journeyItem.endDate) {
          journeyItem.endDate = Timestamp.fromDate(
            new Date(journeyItem.endDate)
          );
        }

        //Add the journeyName to the journeyItem
        journeyItem.journeyName = journeyItem.journeyName;

        //Add the journeyDescription to the journeyItem
        journeyItem.aboutJourney = journeyItem.aboutJourney;

        // Add the user details to the journeyItem
        console.log(user);
        journeyItem.userId = user.uid;
        journeyItem.userName = user.userName;

        // Add the journeyItem to the JourneyItems collection
        const journeyItemDocRef = await addDoc(
          collection(db, "Journeys"),
          journeyItem
        );
        console.log("Document written with ID: ", journeyItemDocRef.id);

        // Update user
        const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id
        const userSnap = await getDoc(userRef);
        let userData = userSnap.data(); // Use a different variable name here

        // Add the new journey to the user's journeys array
        const journey = {
          id: journeyItemDocRef.id,
          name: journeyItem.journeyName,
          location: {
            city: journeyItem.city ? journeyItem.city : "N/A",
            state: journeyItem.state ? journeyItem.state : "N/A",
            country: journeyItem.country ? journeyItem.country : "N/A",
          },
        };

        // Check if the user's journeys array exists
        if (Array.isArray(userData.journeys)) {
          // If it exists, add the new journey to it
          userData.journeys.push(journey);
        } else {
          // If it doesn't exist, create it and add the new journey to it
          userData.journeys = [journey];
        }

        // Update user document in Firestore
        await updateDoc(userRef, userData);
        // Return the new journey item
        return { id: journeyItemDocRef.id, ...journeyItem };
      } else {
        console.log("selectedDestination is null");
      }

      onClose();
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e; // re-throw the error to stop execution
    } finally {
      setLoading(false);
    }

    console.log("Ending createNewJourneyItem function");
  };

  const onSubmit = async (data) => {
    console.log("Submitting Form");
    console.log(data);

    // Prepare the data to be sent to the database
    const dataToSend = {
      ...data,
      category: "Journey",
    };

    if (selectedDestination === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a destination!",
        customClass: {
          container: "swal",
        },
      });
      return;
    }

    try {
      console.log("Creating new journey item...");
      const newJourneyItem = await createNewJourney(dataToSend);
      console.log("New journey item created:", newJourneyItem);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `New item created successfully!`,
        customClass: {
          container: "swal",
        },
      });
      onClose();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div className="form-container">
      <div className="Itemform">
        <div className="formHeading">
          <h1 className="headingdark">Add a new </h1>
          <h1 className="headingLight">Journey</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="journeyName"
                name="journeyName"
                label="Journey Name"
                fullWidth
                {...register("journeyName", { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="aboutJourney"
                name="aboutJourney"
                label="Journey Description"
                fullWidth
                {...register("aboutJourney", { required: true })}
              />
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel">Start Date</InputLabel>
              <Controller
                name="startDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {errors.startDate && errors.startDate.message}
              </FormHelperText>
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel">End Date</InputLabel>
              <Controller
                name="endDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel" id="hotel-label">
                Choose Your Destination
              </InputLabel>
              <PlacesAutocomplete
                value={autocompleteValue}
                onChange={setAutocompleteValue}
                onSelect={(description, placeId) => {
                  setAutocompleteValue(description);

                  const request = {
                    placeId: placeId,
                    fields: [
                      "name",
                      "formatted_address",
                      "photos",
                      "types",
                      "geometry",
                      "address_components",
                    ],
                  };

                  const service = new google.maps.places.PlacesService(
                    document.createElement("div")
                  );
                  service.getDetails(request, (place, status) => {
                    if (
                      status === google.maps.places.PlacesServiceStatus.OK &&
                      place.geometry &&
                      place.address_components
                    ) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      const geopoint = new GeoPoint(lat, lng);

                      let city,
                        state,
                        country,
                        countryCode = "N/A"; // Initialize city, state, country, and countryCode to "N/A"

                      for (
                        let i = 0;
                        i < place.address_components.length;
                        i++
                      ) {
                        const component = place.address_components[i];

                        if (component.types.includes("locality")) {
                          city = component.short_name;
                          console.log("locality: " + component.short_name);
                        }

                        if (
                          component.types.includes(
                            "administrative_area_level_1"
                          )
                        ) {
                          state = component.long_name; // Keep using long_name for the state
                        }

                        if (component.types.includes("country")) {
                          country = component.long_name; // Keep using long_name for the country
                          countryCode = component.short_name; // short_name is the country code
                        }
                      }

                      // ...

                      const destinationData = {
                        name: place.name,
                        address: place.formatted_address,
                        image: place.photos ? place.photos[0].getUrl() : null,
                        itemType: "Destination",
                        geopoint: geopoint,
                        placeId: placeId,
                        city: city || "N/A", // If city is undefined, use "N/A" instead
                        state: state || "N/A", // If state is undefined, use "N/A" instead
                        country: country || "N/A", // If country is undefined, use "N/A" instead
                        countryCode: countryCode || "N/A", // If countryCode is undefined, use "N/A" instead
                      };
                      setSelectedDestination(destinationData);
                    } else {
                      console.error(
                        "Error: No location data available for this place.",
                        "Status:",
                        status,
                        "Place:",
                        place
                      );
                    }
                  });
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search for destinations...",
                        className: "hotel-input", // Add this line
                        style: {
                          width: "auto",
                          height: "40px",
                          padding: "10px",
                        }, // Add this line
                      })}
                    />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41b6e6"
                            : "#fff",
                          cursor: "pointer",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style,
                              key: suggestion.placeId,
                            })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12}>
              <Button className="submitButton" type="submit">
                Submit
              </Button>
              <Button className="cancelButton" type="button" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default NewJourney;
