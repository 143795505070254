/* global google */
import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { BeatLoader } from "react-spinners";
import "./cruiseFeed.css";
import CruiseJourneyItem from "../items/cruiseJourneyItem";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const CruiseFeed = ({
  userId,
  user,
  onItemUpdate,
  setItems,
  items,
  refresh,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(true);
  const [cruiseLines, setCruiseLines] = useState([]);
  const [ships, setShips] = useState([]);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState("");
  const [selectedShip, setSelectedShip] = useState("");
  const [departurePort, setDeparturePort] = useState("");
  const [departureDate, setDepartureDate] = useState("");

  const db = getFirestore();

  // Initialize Algolia
  const client = algoliasearch(
    "1REB6WCMBC",
    "f95d9e3225c839f9b5fff98b732406db"
  );
  const CruiseIndex = client.initIndex("CruiseJourneys");

  useEffect(() => {
    const fetchCruiseLines = async () => {
      const cruiseLinesCollection = collection(db, "Cruises");
      const cruiseLinesSnapshot = await getDocs(cruiseLinesCollection);
      const cruiseLinesData = cruiseLinesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCruiseLines(cruiseLinesData);
    };

    fetchCruiseLines();
  }, [db]);

  useEffect(() => {
    const fetchShips = async () => {
      if (selectedCruiseLine) {
        const shipsCollection = collection(
          db,
          `Cruises/${selectedCruiseLine}/Ships`
        );
        const shipsSnapshot = await getDocs(shipsCollection);
        const shipsData = shipsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShips(shipsData);
      } else {
        setShips([]);
      }
    };

    fetchShips();
  }, [selectedCruiseLine, db]);

  const handleSearch = async () => {
    setLoading(true);
    const filters = [];

    if (selectedCruiseLine) filters.push(`cruise.id:${selectedCruiseLine}`);
    if (selectedShip) filters.push(`ship.id:${selectedShip}`);
    if (departurePort) filters.push(`departurePort:${departurePort}`);
    if (departureDate)
      filters.push(`departureDate:${new Date(departureDate).getTime()}`);

    const filterString = filters.join(" AND ");

    const { hits } = await CruiseIndex.search("", {
      filters: filterString || undefined, // Use undefined if no filters to get all items
    });

    console.log("Filter String:", filterString); // Debugging line
    console.log("Hits:", hits); // Debugging line

    setItems(hits);
    setLoading(false);
  };

  const handleClear = () => {
    setSelectedCruiseLine("");
    setSelectedShip("");
    setDeparturePort("");
    setDepartureDate("");
    handleSearch();
  };

  useEffect(() => {
    // Load all items initially
    handleSearch();
  }, []);

  return (
    <>
      <div className="filterBox">
        <div className="filterTagLine">
          <p className="filterText">Filter</p>
          <p className="filterHighlight">Cruises</p>
        </div>
        <div className="filterItem">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="cruise-line-select-label">
                  Cruise Line
                </InputLabel>
                <Select
                  labelId="cruise-line-select-label"
                  value={selectedCruiseLine}
                  onChange={(e) => setSelectedCruiseLine(e.target.value)}
                >
                  {cruiseLines.map((line) => (
                    <MenuItem key={line.id} value={line.id}>
                      {line.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="ship-select-label">Cruise Ship</InputLabel>
                <Select
                  labelId="ship-select-label"
                  value={selectedShip}
                  onChange={(e) => setSelectedShip(e.target.value)}
                  disabled={!selectedCruiseLine}
                >
                  {ships.map((ship) => (
                    <MenuItem key={ship.id} value={ship.id}>
                      {ship.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Departure Port"
                value={departurePort}
                onChange={(e) => setDeparturePort(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Departure Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={departureDate}
                onChange={(e) => setDepartureDate(e.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "1em" }}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#1bb6ca",
                borderColor: "#1bb6ca",
                "&:hover": { borderColor: "#17a2b8", color: "#17a2b8" },
              }}
              onClick={handleClear}
              style={{ marginLeft: "1em" }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginTop: "2em",
            height: "100vh",
          }}
        >
          <BeatLoader color="#123abc" loading={loading} size={15} />
        </div>
      ) : items && items.length > 0 ? (
        items.map((item) => (
          <CruiseJourneyItem
            key={item.objectID}
            item={item}
            onItemUpdate={onItemUpdate}
            user={user}
            userId={userId}
          />
        ))
      ) : (
        <div>
          <p
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              color: "#415063",
            }}
          >
            Be the first to add content for this location!
          </p>
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://discord.com/widget?id=1049492045015822366&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>`,
        }}
      />
    </>
  );
};

export default CruiseFeed;
