import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sendPasswordResetEmail } from "firebase/auth";
import UserContext from "../../UserContext";
import Swal from "sweetalert2";
import { auth, db } from "../../firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import "./Navbar.css";

export default function Navbar() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const user = useContext(UserContext);
  const { userName } = user || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [journeyAnchorEl, setJourneyAnchorEl] = useState(null);
  const [journeys, setJourneys] = React.useState([]);
  const [openMenu, setOpenMenu] = useState(null);


  const handleJourneyMenuOpen = useCallback((event) => {
    setJourneyAnchorEl(event.currentTarget);
  }, []);

  const handleJourneyMenuClose = (event) => {
    if (event.relatedTarget && event.relatedTarget.id === "parent-menu-item") {
      // Don't close the menu if the click event is coming from the parent MenuItem
      return;
    }

    setJourneyAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuOpen = useCallback((event) => {
    setOpenMenu(event.currentTarget);
  }, []);

  const handleMenuClose = (event) => {
    if (event.relatedTarget && event.relatedTarget.id === "parent-menu-item") {
      // Don't close the menu if the click event is coming from the parent MenuItem
      return;
    }

    setMenuAnchorEl(null);
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) =>
      setIsSignedIn(!!user)
    );
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const openSignInForm = () => {
    Swal.fire({
      title: "Sign In",
      html: `
        <input type="text" id="email" class="swal2-input" placeholder="Email">
        <input type="password" id="password" class="swal2-input" placeholder="Password">
        <div><a href="javascript:void(0);" id="forgot-password" style="text-decoration: underline;">Forgot Password?</a></div>
      `,
      confirmButtonText: "Sign In",
      focusConfirm: false,
      preConfirm: () => {
        const email = Swal.getPopup().querySelector("#email").value;
        const password = Swal.getPopup().querySelector("#password").value;

        if (!email || !password) {
          Swal.showValidationMessage(`Please enter email and password`);
        }

        return { email, password };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        signInWithEmailAndPassword(
          auth,
          result.value.email,
          result.value.password
        )
          .then((userCredential) => {
            // User signed in successfully.
          })
          .catch((error) => {
            // Handle Errors here.
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    });
    // Add event listener outside of Swal
    document.getElementById("forgot-password").addEventListener("click", () => {
      Swal.fire({
        title: "Enter your email address",
        input: "email",
        inputPlaceholder: "Your email address",
        confirmButtonText: "Send Reset Link",
        preConfirm: (email) => {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              // Password reset email sent
              Swal.fire({
                title: "Success!",
                text: "Password reset email has been sent!",
                icon: "success",
                confirmButtonText: "OK",
              });
            })
            .catch((error) => {
              // Handle Errors
              Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        },
      });
    });
  };

  const openSignInPrompt = () => {
    Swal.fire({
      title: "Please sign in",
      text: "Choose an option below.",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sign In",
      cancelButtonText: "Sign Up",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        openSignInForm(); // Call the function to open Sign In form
      } else if (result.isDismissed) {
        openSignUpForm(); // Call the function to open Sign Up form
      }
    });
  };

  const openSignUpForm = () => {
    Swal.fire({
      title: "Sign Up",
      html: `
      <input type="text" id="username" class="swal2-input" placeholder="Username" style="font-size: 14px;">
      <input type="text" id="email" class="swal2-input" placeholder="Email" style="font-size: 14px;">
      <input type="password" id="password" class="swal2-input" placeholder="Password" style="font-size: 14px;">
      <input type="text" id="aboutUser" class="swal2-input" placeholder="About You" style="font-size: 14px;">
      <input type="text" id="facebook" class="swal2-input" placeholder="Facebook" style="font-size: 14px;">
      <input type="text" id="instagram" class="swal2-input" placeholder="Instagram" style="font-size: 14px;">
      <input type="text" id="youtube" class="swal2-input" placeholder="YouTube" style="font-size: 14px;">
      <input type="text" id="tiktok" class="swal2-input" placeholder="TikTok" style="font-size: 14px;">
      <input type="text" id="x" class="swal2-input" placeholder="X" style="font-size: 14px;">
      `,
      confirmButtonText: "Sign Up",
      focusConfirm: false,
      preConfirm: async () => {
        const userName = Swal.getPopup().querySelector("#username").value;
        const email = Swal.getPopup().querySelector("#email").value;
        const password = Swal.getPopup().querySelector("#password").value;
        const aboutUser = Swal.getPopup().querySelector("#aboutUser").value;
        const facebook = Swal.getPopup()
          .querySelector("#facebook")
          .value.toLowerCase();
        const instagram = Swal.getPopup()
          .querySelector("#instagram")
          .value.toLowerCase();
        const youtube = Swal.getPopup()
          .querySelector("#youtube")
          .value.toLowerCase();
        const tiktok = Swal.getPopup()
          .querySelector("#tiktok")
          .value.toLowerCase();
        const x = Swal.getPopup().querySelector("#x").value.toLowerCase();

        if (!userName || !email || !password) {
          Swal.showValidationMessage(
            `Please enter username, email, and password`
          );
        }

        // Check if the username already exists in Firestore
        const checkUsernameExists = async (userName) => {
          const usersCollection = collection(db, "Users");
          const q = query(usersCollection, where("userName", "==", userName));
          const snapshot = await getDocs(q);
          return !snapshot.empty;
        };

        const usernameExists = await checkUsernameExists(userName);

        if (usernameExists) {
          Swal.fire({
            title: "Error!",
            text: "The username is already in use. Please choose a different one.",
            icon: "error",
            confirmButtonText: "OK",
          });
          return null; // Returning null prevents the form from closing
        }

        return {
          userName,
          email,
          password,
          aboutUser,
          facebook,
          instagram,
          youtube,
          tiktok,
          x,
        };
      },
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        try {
          // Create a new user with email and password using Firebase Authentication
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            result.value.email,
            result.value.password
          );

          const user = userCredential.user;
          console.log(user);

          // Save user data to Firestore
          await saveUserDataToFirestore(
            user.uid,
            result.value.userName,
            result.value.email,
            result.value.aboutUser,
            result.value.facebook,
            result.value.instagram,
            result.value.youtube,
            result.value.tiktok,
            result.value.x
          );

          // If everything is successful
          Swal.fire({
            title: "Success!",
            text: "User created successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          // Handle errors
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const saveUserDataToFirestore = async (
    uid,
    userName,
    email,
    aboutUser,
    facebook,
    instagram,
    youtube,
    tiktok,
    x
  ) => {
    console.log("saveUserDataToFirestore started");
    const batch = writeBatch(db);

    // Create an empty User document with basic info and empty teams array
    console.log("Creating user document");
    await setDoc(doc(collection(db, "Users"), uid), {
      uid,
      userName,
      email,
      type: "User",
      aboutUser,
      facebook,
      instagram,
      youtube,
      tiktok,
      x,
      createdAt: serverTimestamp(),
    });
    console.log("User document created");

    console.log("Committing batch");
    await batch.commit();
    console.log("Batch committed");
  };

  useEffect(() => {
    if (isSignedIn && user) {
      const fetchJourneys = async () => {
        const userRef = doc(collection(db, "Users"), user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists) {
          setJourneys(userDoc.data().journeys);
        } else {
          console.log("No such document!");
        }
      };

      fetchJourneys();
    }
  }, [isSignedIn, user]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#1bb6ca", zIndex: 9999 }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="parent-menu-item"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: { backgroundColor: "transparent", boxShadow: "none" },
            }}
          >
            <MenuItem>
              <Accordion sx={{ backgroundColor: "#fff" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    My Journeys
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {journeys && journeys.length > 0 ? (
                      journeys.map((journey, index) => (
                        <ListItem key={index}>
                          <Link
                            to={`/journey/${journey.id}`}
                            style={{ color: "inherit", textDecoration: "none" }}
                          >
                            {journey.name} - {journey.location.country}
                          </Link>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <span>No journeys available</span>
                      </ListItem>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <a href="/" style={{ color: "inherit", textDecoration: "none" }}>
              Kaleido Travel
            </a>
          </Typography>
          {!isSignedIn ? (
            <Button color="inherit" onClick={openSignInPrompt}>
              Login
            </Button>
          ) : (
            <>
              <Button color="inherit" onClick={handleClick}>
                {userName ? userName : <CircularProgress size={24} />}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
