import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  LinearProgress,
  Grid,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.svg";
import tiktok from "../../Images/tiktok.svg";
import x from "../../Images/x.png";
import youtube from "../../Images/youtube.png";

const UserStats = ({ item, userData }) => {
  const getProgressValue = (points) => {
    return (points % 50) * 2;
  };

  return (
    <Card sx={{ margin: 2, color: "#415063" }} elevation={0}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {item.userName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rank: {userData && userData.stats && userData.stats.rank}
          <br />
          {userData && userData.stats && userData.stats.points} points
        </Typography>
        {userData && userData.CheckIn && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div">
                Current Location:
              </Typography>
              {userData.CheckIn.country && (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #1bb6ca",
                    marginRight: "5px",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={userData.CheckIn.countryCode}
                    svg
                    style={{
                      width: "50px",
                      height: "auto",
                    }}
                    title={userData.CheckIn.country}
                  />
                </div>
              )}
              <Typography variant="body2" color="text.secondary">
                {userData.CheckIn.name && (
                  <p>
                    <span className="blinking-dot-stats"></span>{" "}
                    {userData.CheckIn.name}
                  </p>
                )}
                {userData.CheckIn.address && <p>{userData.CheckIn.address}</p>}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              {item.category} Stats:
            </Typography>
            {userData && userData.stats && userData.stats[item.category] ? (
              <>
                <Typography variant="body2" color="text.secondary">
                  Points: {userData.stats[item.category].points}
                  <br />
                  Rank: {userData.stats[item.category].rank}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={getProgressValue(userData.stats[item.category].points)}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    width: "100%",
                    marginTop: 1,
                  }}
                />
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No data available for this category.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Countries visited:
            </Typography>
            {userData && userData.stats && userData.stats.locations && (
              <Grid container spacing={1}>
                {Object.keys(userData.stats.locations).map((country) => {
                  const countryCode =
                    userData.stats.locations[country].countryCode;
                  return (
                    <Grid item key={country}>
                      <div
                        key={country}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #1bb6ca",
                          marginRight: "5px",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={countryCode}
                          svg
                          style={{
                            width: "75px",
                            height: "auto",
                          }}
                          title={country}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Socials:
            </Typography>
            <div className="itemSocials">
              {userData && userData.facebook && (
                <IconButton href={userData.facebook} target="_blank">
                  <img src={facebook} alt="Facebook" className="iconImage" />
                </IconButton>
              )}
              {userData && userData.instagram && (
                <IconButton href={userData.instagram} target="_blank">
                  <img src={instagram} alt="Instagram" className="iconImage" />
                </IconButton>
              )}
              {userData && userData.tiktok && (
                <IconButton href={userData.tiktok} target="_blank">
                  <img src={tiktok} alt="TikTok" className="iconImage" />
                </IconButton>
              )}
              {userData && userData.x && (
                <IconButton href={userData.x} target="_blank">
                  <img src={x} alt="X" className="iconImage" />
                </IconButton>
              )}
              {userData && userData.youtube && (
                <IconButton href={userData.youtube} target="_blank">
                  <img src={youtube} alt="YouTube" className="iconImage" />
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
        {item.journey && (
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Link to={`/journey/${item.journey.id}`}>
                <Chip
                  label="Click here to view the full Journey"
                  className="userChip"
                  sx={{
                    color: "white",
                    backgroundColor: "#1bb6ca",
                    fontSize: "1rem",
                    zIndex: "0",
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UserStats;
