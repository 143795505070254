/* global */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Swal from "sweetalert2";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./newItem.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const firestore = getFirestore(); // Initialize Firestore

function NewJourneyItinerary(props) {
  const { onClose } = props;
  const { journey } = props;
  const [days, setDays] = useState([
    {
      day: 1,
      date: "",
      type: "",
      location: "",
      placeId: "",
      lat: "",
      lng: "",
    },
  ]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ name: "", videoLink: "" }],
      // other form fields...
    },
  });

  const addDay = () => {
    setDays([
      ...days,
      {
        day: days.length + 1,
        date: "",
        type: "",
        location: "",
        placeId: "",
        lat: "",
        lng: "",
      },
    ]);
  };

  const handleTypeChange = (index, type) => {
    const newDays = [...days];
    newDays[index].type = type;
    setDays(newDays);
  };

  const handleSelect = async (index, address, type) => {
    const newDays = [...days];
    newDays[index][type] = address;
    setDays(newDays);

    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      console.log("results:", results);

      // Use PlacesService to get detailed information about the place
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const commonData = {
            name: address,
            placeId,
            address,
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "N/A",
            state:
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.short_name || "N/A",
            country:
              addressComponents.find((component) =>
                component.types.includes("country")
              )?.long_name || "N/A",
            countryCode:
              addressComponents.find((component) =>
                component.types.includes("country")
              )?.short_name || "N/A",
            geopoint: {
              latitude: latLng.lat,
              longitude: latLng.lng,
            },
          };

          if (type === "location") {
            newDays[index] = {
              ...newDays[index],
              ...commonData,
            };
          } else if (type === "startLocation") {
            newDays[index] = {
              ...newDays[index],
              ...commonData,
              startLocation: address,
            };
          } else if (type === "transitLocation") {
            newDays[index] = {
              ...newDays[index],
              transitLocation: {
                ...commonData,
                name: address,
              },
            };
          }

          setDays(newDays);

          newDays[index] = {
            ...newDays[index],
            lat: latLng.lat,
            lng: latLng.lng,
            placeId: placeId,
          };
          setDays(newDays);
        } else {
          console.error("PlacesService failed due to: ", status);
        }
      });
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const onSubmit = async (data) => {
    const firestore = getFirestore(); // Initialize Firestore
    try {
      const JourneyRef = doc(firestore, "Journeys", journey.id);
      await updateDoc(JourneyRef, {
        itinerary: {
          ...data,
          days,
          createdAt: serverTimestamp(),
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Itinerary updated successfully.",
        customClass: {
          container: "swal",
        },
      }).then(() => {
        window.location.reload(); // Refresh the page
      });
      onClose();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: "20px" }}>
          <div className="formHeading">
            <h1 className="headingdark">Add a new </h1>
            <h1 className="headingLight">Itinerary</h1>
          </div>
          <Grid
            className="formControl"
            container
            direction="column"
            spacing={2}
          >
            {days.map((day, index) => (
              <Grid item key={index}>
                <InputLabel className="inputLabel">Day {day.day}</InputLabel>
                <Controller
                  name={`days[${index}].date`}
                  control={control}
                  defaultValue={day.date}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        const newDays = [...days];
                        newDays[index].date = e.target.value;
                        setDays(newDays);
                      }}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.date?.message}
                </FormHelperText>
                <Controller
                  name={`days[${index}].isTransitDay`}
                  control={control}
                  defaultValue={day.isTransitDay || false}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Are you travelling to a new destination on this day?
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        onChange={(e) => {
                          field.onChange(e);
                          const newDays = [...days];
                          newDays[index].isTransitDay =
                            e.target.value === "yes";
                          setDays(newDays);
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <FormHelperText error>
                  {errors.days && errors.days[index]?.isTransitDay?.message}
                </FormHelperText>
                {day.isTransitDay ? (
                  <>
                    <PlacesAutocomplete
                      value={day.startLocation}
                      onChange={(address) => {
                        const newDays = [...days];
                        newDays[index].startLocation = address;
                        setDays(newDays);
                      }}
                      onSelect={(address) =>
                        handleSelect(index, address, "startLocation")
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              label: "Where did you start on this day?",
                              fullWidth: true,
                              sx: { marginTop: "10px" },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <PlacesAutocomplete
                      value={
                        day.transitLocation ? day.transitLocation.address : ""
                      }
                      onChange={(address) => {
                        const newDays = [...days];
                        newDays[index].transitLocation = { address };
                        setDays(newDays);
                      }}
                      onSelect={(address) =>
                        handleSelect(index, address, "transitLocation")
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              label: "Where are you travelling to?",
                              fullWidth: true,
                              sx: { marginTop: "10px" },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </>
                ) : (
                  <>
                    <PlacesAutocomplete
                      value={day.location}
                      onChange={(address) => {
                        const newDays = [...days];
                        newDays[index].location = address;
                        setDays(newDays);
                      }}
                      onSelect={(address) =>
                        handleSelect(index, address, "location")
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              label: "Where will you be this day?",
                              fullWidth: true,
                              sx: { marginTop: "10px" },
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </>
                )}
              </Grid>
            ))}
            <Grid item>
              <Button variant="contained" onClick={addDay}>
                + Add Day
              </Button>
            </Grid>
            <hr />
            <Grid item>
              <div className="formButtons">
                <button className="submitButton" type="submit">
                  Submit
                </button>
                <button
                  className="cancelButton"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default NewJourneyItinerary;
