import React, { useState, useEffect } from "react";
import {
  Chip,
  CircularProgress,
  IconButton,
  GlobalStyles,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Modal from "react-modal";
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

Modal.setAppElement("#root");

function Experience({ data }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const windowWidth = useWindowWidth();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  if (!data) {
    return <CircularProgress />; // return a loading spinner
  }

  let EmbedComponent = null;
  let MediaIcon = null;
  let mediaDimensions = { width: 500, height: 500 }; // Default dimensions

  if (
    data.videoLink.includes("youtube") ||
    data.videoLink.includes("youtu.be")
  ) {
    EmbedComponent = YouTubeEmbed;
    MediaIcon = <FontAwesomeIcon icon={faYoutube} style={{ color: "red" }} />;
    mediaDimensions =
      windowWidth < 768
        ? { height: "auto", width: "100%" }
        : { height: 600, width: 1000 };
  } else if (data.videoLink.includes("instagram")) {
    EmbedComponent = InstagramEmbed;
    MediaIcon = (
      <FontAwesomeIcon icon={faInstagram} style={{ color: "purple" }} />
    );
    mediaDimensions =
      windowWidth < 768 ? { width: "90vw" } : { height: 1000, width: 600 };
  } else if (data.videoLink.includes("tiktok")) {
    EmbedComponent = TikTokEmbed;
    MediaIcon = <FontAwesomeIcon icon={faTiktok} style={{ color: "black" }} />;
    mediaDimensions =
      windowWidth < 768
        ? { height: "auto", width: "100%" }
        : { height: 800, width: 325 };
  }

  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
  }

  let marginTopDesktop = "0px";
  let marginTopMobile = "0px";
  if (EmbedComponent === InstagramEmbed) {
    marginTopDesktop = "150px";
    marginTopMobile = "190px";
  } else if (EmbedComponent === TikTokEmbed) {
    marginTopDesktop = "0px";
    marginTopMobile = "300px";
  }

  return (
    <div>
      <GlobalStyles
        styles={{
          ".MuiChip-label": {
            whiteSpace: "nowrap !important",
          },
        }}
      />
      <Chip
        label={data.rideName ? `${data.name} - ${data.rideName}` : data.name}
        onClick={openModal}
        icon={MediaIcon} // Add the icon to the Chip
        sx={{
          cursor: "pointer",
          backgroundColor: "#1bb6ca",
          color: "white",
          fontSize: "1.2rem",
        }}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Media Modal"
        shouldCloseOnOverlayClick={true} // Close the modal when the overlay is clicked
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)", // This will make the overlay fully transparent
            display: "flex", // Use Flexbox to center the content
            alignItems: "center", // Center the content vertically
            justifyContent: "center", // Center the content horizontally
            overflow: "auto", // Allow the modal to be scrolled if needed
          },
          content: {
            position: "static", // Override the default absolute positioning
            marginTop: windowWidth < 768 ? marginTopMobile : marginTopDesktop, // Use different margins for mobile and desktop
            marginBottom: "100px",
            inset: "unset", // Override the default inset
            color: "lightgrey", // This will make the text color light grey
            overflow: "auto", // Allow the modal content to be scrolled if needed
            width: `${
              windowWidth < 768
                ? mediaDimensions.width + 50
                : mediaDimensions.width + 50
            }px`, // Set the width of the modal
            height: `${
              windowWidth < 768
                ? mediaDimensions.height + 50
                : mediaDimensions.height + 50
            }px`, // Set the height of the modal
            backgroundColor: "#1bb6ca", // Set the background color of the modal
          },
        }}
      >
        <IconButton
          onClick={closeModal}
          style={{
            position: "absolute",
            top: 54,
            right: 0,
            color: "white",
            padding: "10px",
            fontSize: "2rem !important",
          }}
        >
          <Close />
        </IconButton>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "auto", // Allow the div to be scrolled if the EmbedComponent overflows it
          }}
        >
          {EmbedComponent && (
            <EmbedComponent
              url={data.videoLink}
              {...mediaDimensions}
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Experience;
