import React, { forwardRef, useRef, useEffect } from "react";
import { format, fromUnixTime } from "date-fns";
import JourneyBG from "./JourneyBG.png";
import QRCodeStyling from "qr-code-styling";
import Image from "./Favicon Transparent.ico";
import "./ItemShareImage.css";

const InstagramShareImage = forwardRef(({ item }, ref) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 200,
      height: 200,
      data: `www.kaleidotravelvlogs.com/${item.objectID}`,
      image: Image,
      dotsOptions: {
        color: "#1bb6ca",
        type: "dots",
      },
      cornersSquareOptions: {
        color: "#415063",
      },
      cornersDotOptions: {
        color: "#1bb6ca",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
    });

    if (qrCodeRef.current) {
      qrCode.append(qrCodeRef.current);
    }
  }, [item.objectID]);
  return (
    <div
      ref={ref}
      className="food-share-image"
      style={{
        backgroundImage: `url(${JourneyBG})`,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        borderRadius: "10px",
        border: "4px solid #1bb6ca",
      }}
    >
      <div className="share-content">
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${
              item.photos && item.photos.length > 0
                ? item.photos[0].url
                : item.imageUrl
            })`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
          }}
        ></div>
        <div className="item-polaroid-content">
          <h2 className="item-share-title">{item.name}</h2>
          {item.startDate && (
            <p className="item-share-date">
              {format(fromUnixTime(item.startDate / 1000), "do 'of' MMMM yyyy")}
              {item.endDate &&
                ` to ${format(
                  fromUnixTime(item.endDate / 1000),
                  "do 'of' MMMM yyyy"
                )}`}
            </p>
          )}
          <h2 className="item-share-user">{item.userName}</h2>
          <p className="item-share-desc">{item.address}</p>
        </div>
      </div>

      <div className="qr-code-container">
        <div className="qr-code" ref={qrCodeRef}></div>
        <p className="qr-code-text">
          Discover the full experience at Kaleido Travel
          <br />
          www.kaleidotravelvlogs.com/{item.objectID}
        </p>
      </div>
    </div>
  );
});

export default InstagramShareImage;
