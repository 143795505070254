import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { db } from "../../firebase.js";
import Map from "../../Components/homeMap/homeMap";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import JourneyForm from "../../Components/items/newItem.js";
import NewJourney from "../../Components/items/newJourney.js";
import UserContext from "../../UserContext";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/material/Chip";
import HotelItem from "../../Components/items/hotelItem";
import DestinationItem from "../../Components/items/destinationItem";
import ActivityItem from "../../Components/items/activityItem";
import FoodItem from "../../Components/items/foodItem";
import ThemeParkItem from "../../Components/items/themeParkItem";
import UserItem from "../../Components/items/userItem";
import JourneyItemCategory from "../../Components/items/journeyItem";
import CheckInCard from "../../Components/items/CheckInCard.js";
import {
  doc,
  getDoc,
  collection,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { BeatLoader } from "react-spinners";
import Sidebar from "../../Components/Sidebar/sideBar.js";
import RightSidebar from "../../Components/Sidebar/rightSideBar.js";

function ItemPage() {
  const context = useContext(UserContext);
  const { journeys, dreamLists, setDreamLists, userDetails } = context || {};
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openJourney, setOpenJourney] = useState(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const userId = user ? user.uid : null;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [dreamItems, setDreamItems] = useState([]);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "JourneyItems", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Convert Firestore Timestamps to Unix timestamps
          if (data.startDate && data.startDate.toMillis) {
            data.startDate = data.startDate.toMillis();
          }
          if (data.endDate && data.endDate.toMillis) {
            data.endDate = data.endDate.toMillis();
          }
          setItem({ id: docSnap.id, ...data });
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchItem();
    }
  }, [id]);

  const renderItem = (item) => {
    switch (item.category) {
      case "Hotel":
        return <HotelItem item={item} userId={userId} />;
      case "Destination":
        return <DestinationItem item={item} userId={userId} />;
      case "Activity":
        return <ActivityItem item={item} userId={userId} />;
      case "Food":
        return <FoodItem item={item} userId={userId} />;
      case "ThemePark":
        return <ThemeParkItem item={item} userId={userId} />;
      case "User":
        return <UserItem item={item} userId={userId} />;
      case "Journey":
        return <JourneyItemCategory item={item} userId={userId} />;
      default:
        return null;
    }
  };

  const handleDeleteList = async (listId) => {
    try {
      // Delete the document from the DreamLists collection
      const listRef = doc(db, "DreamLists", listId);
      await deleteDoc(listRef);

      // Remove the reference from the user's document
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        dreamLists: arrayRemove(listId),
      });

      // Update the state after deletion
      setDreamLists((prevLists) =>
        prevLists.filter((list) => list.id !== listId)
      );
    } catch (error) {
      console.error("Error deleting dream list:", error);
      Swal.fire("Error", "Failed to delete the dream list.", "error");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <Map items={item} />
      <div className="fullWidthHome">
        <Sidebar
          dreamLists={dreamLists}
          journeys={journeys}
          onDelete={handleDeleteList}
          setDreamLists={setDreamLists}
          user={user}
        />
        <div className="homePageContainer">
          <header className="App-header">
            <div className="tagLine">
              <p className="homeText" style={{ marginRight: "5px" }}>
                Kaleido
              </p>
              <p className="highlight"> Travel</p>
            </div>
            <div>
              <p className="heroP">
                Your interactive diary for sharing your journeys and inspiring
                perfect travel experiences for our community.
              </p>
              <div className="homePageButtons">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/"
                    sx={{ backgroundColor: "#1bb6ca" }}
                  >
                    Back to Home
                  </Button>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <JourneyForm
                    user={user ? user : null}
                    onClose={handleClose}
                    setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
                  />
                </Modal>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  marginTop: "2em",
                  height: "100vh",
                }}
              >
                <BeatLoader color="#123abc" loading={loading} size={15} />
              </div>
            ) : (
              renderItem(item)
            )}
          </header>
        </div>
        <RightSidebar />
        <Footer />
      </div>
    </div>
  );
}

export default ItemPage;
