import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import { getFirestore, collection, doc, setDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function CruiseUploadPage() {
  const [formData, setFormData] = useState({
    cruiseId: "",
    name: "",
    operator: "",
    shipyard: "",
    length: "",
    capacity: "",
    crew: "",
    gt: "",
  });

  const [cruises, setCruises] = useState([]);

  useEffect(() => {
    const fetchCruises = async () => {
      const cruisesCollection = collection(db, "Cruises");
      const cruiseSnapshot = await getDocs(cruisesCollection);
      const cruiseList = cruiseSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCruises(cruiseList);
    };

    fetchCruises();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { cruiseId, ...shipData } = formData;
    try {
      const shipRef = doc(collection(db, `Cruises/${cruiseId}/Ships`));
      await setDoc(shipRef, shipData);
      alert("Ship uploaded successfully!");
      setFormData({
        cruiseId: "",
        name: "",
        operator: "",
        shipyard: "",
        length: "",
        capacity: "",
        crew: "",
        gt: "",
      });
    } catch (error) {
      console.error("Error uploading ship: ", error);
      alert("Error uploading ship. Please try again.");
    }
  };

  return (
    <div className="App">
      <Navbar />
      <header style={{ marginTop: "50px", marginLeft: "auto", marginRight: "auto" }}>
        <div className="tagLine">
          <p className="homeText">Kaleido</p>
          <p className="highlight"> Travel</p>
        </div>
        <div>
          <p className="heroP">Cruise Ship Maintenance</p>
          <div className="homePageButtons"></div>
        </div>
      </header>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50vw",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel>Cruise</InputLabel>
          <Select
            value={formData.cruiseId}
            onChange={(e) => setFormData({ ...formData, cruiseId: e.target.value })}
            label="Cruise"
          >
            {cruises.map((cruise) => (
              <MenuItem key={cruise.id} value={cruise.id}>
                {cruise.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Operator"
          name="operator"
          value={formData.operator}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Shipyard"
          name="shipyard"
          value={formData.shipyard}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Length"
          name="length"
          value={formData.length}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="GT"
          name="gt"
          value={formData.gt}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Capacity"
          name="capacity"
          value={formData.capacity}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Crew"
          name="crew"
          value={formData.crew}
          onChange={handleChange}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Upload Ship
        </Button>
      </Box>

      <Footer />
    </div>
  );
}

export default CruiseUploadPage;