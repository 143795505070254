import React, { useState, useContext } from "react";
import { Chip, Menu, MenuItem, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import JourneyForm from "../../Components/items/newItem";
import NewJourney from "../../Components/items/newJourney";
import UserContext from "../../UserContext"; // Ensure you have the correct context import

const AddMenu = ({ setRefresh }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openJourney, setOpenJourney] = useState(false);
  const user = useContext(UserContext);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "experience") {
      handleOpen();
    } else if (action === "journey") {
      handleOpenJourney();
    }
  };

  const handleOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleOpenJourney = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add a journey",
      });
    } else {
      setOpenJourney(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseJourney = () => {
    setOpenJourney(false);
  };

  return (
    <div>
      <Chip
        icon={<AddIcon style={{ color: "white" }} />}
        label="Create"
        onClick={handleMenuClick}
        style={{
          cursor: "pointer",
          backgroundColor: "#1bb6ca",
          color: "white",
          width: "300px",
          height: "50px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          margin: "10px",
          fontSize: "1.2em",
        }}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("experience")}>
          New Experience
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("journey")}>
          New Journey
        </MenuItem>
      </Menu>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <JourneyForm
          user={user ? user : null}
          onClose={handleClose}
          setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
        />
      </Modal>
      <Modal
        open={openJourney}
        onClose={handleCloseJourney}
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <NewJourney
          user={user ? user : null}
          onClose={handleCloseJourney}
          setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
        />
      </Modal>
    </div>
  );
};

export default AddMenu;