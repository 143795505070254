import React, { useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const Map = ({ items }) => {
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  useEffect(() => {
    const mapStyle = [
      {
        stylers: [
          {
            hue: "#2c3e50",
          },
          {
            saturation: 250,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            lightness: 50,
          },
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ];
    const { google } = window;
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: 0, lng: 0 },
      zoom: 2,
      styles: mapStyle, // Apply the custom style
    });

    if (!items || !items.itinerary) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    const days = items.itinerary.days;

    let previousPosition = null;
    let startDay = null;
    let endDay = null;

    // Clear previous markers
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    days.forEach((day, index) => {
      let position;
      if (day.isTransitDay) {
        if (day.transitLocation && day.transitLocation.geopoint) {
          position = {
            lat: day.transitLocation.geopoint.latitude,
            lng: day.transitLocation.geopoint.longitude,
          };
        }
      } else if (day.geopoint) {
        position = {
          lat: day.geopoint.latitude,
          lng: day.geopoint.longitude,
        };
      }

      if (position) {
        if (
          previousPosition &&
          previousPosition.lat === position.lat &&
          previousPosition.lng === position.lng
        ) {
          // Same location as previous day
          if (startDay === null) {
            startDay = index; // Start of the range
          }
          endDay = index; // Update end of the range
        } else {
          // Different location or first day
          if (startDay !== null && endDay !== null) {
            // Add marker for the previous range
            let labelText = `Days ${startDay + 1}-${endDay + 1}`;
            let titleText = `Days ${startDay + 1}-${endDay + 1}: ${
              days[startDay].location
            }`;

            const marker = new google.maps.Marker({
              position: previousPosition,
              map,
              label: {
                text: labelText,
                color: "black",
                fontSize: "15px",
                fontWeight: "bold",
              },
              title: titleText,
            });
            markersRef.current.push(marker);
            bounds.extend(previousPosition);

            // Reset range
            startDay = null;
            endDay = null;
          }

          // Add marker for the current day if it's not part of a range
          if (startDay === null && endDay === null) {
            let labelText = `Day ${day.day}`;
            let titleText = `Day ${day.day}: ${day.location}`;

            const marker = new google.maps.Marker({
              position,
              map,
              label: {
                text: labelText,
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
              },
              title: titleText,
            });
            markersRef.current.push(marker);
            bounds.extend(position);
          }

          // Set new range start
          startDay = index;
          endDay = index;
        }

        previousPosition = position;
      }
    });

    // Add marker for the last range if needed
    if (startDay !== null && endDay !== null) {
      let labelText =
        startDay === endDay
          ? `Day ${startDay + 1}`
          : `Days ${startDay + 1}-${endDay + 1}`;
      let titleText =
        startDay === endDay
          ? `Day ${startDay + 1}: ${days[startDay].location}`
          : `Days ${startDay + 1}-${endDay + 1}: ${days[startDay].location}`;

      const marker = new google.maps.Marker({
        position: previousPosition,
        map,
        label: {
          text: labelText,
          color: "black",
          fontSize: "12px",
          fontWeight: "bold",
        },
        title: titleText,
      });
      markersRef.current.push(marker);
      bounds.extend(previousPosition);
    }

    map.fitBounds(bounds);

    // Set zoom level directly after fitting bounds
    const listener = google.maps.event.addListenerOnce(map, "idle", () => {
      if (map.getZoom() > 8) {
        map.setZoom(8);
      }
      google.maps.event.removeListener(listener);
    });

    // Initialize MarkerClusterer
    const markerCluster = new MarkerClusterer(map, markersRef.current, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  }, [items]);

  return <div ref={mapRef} style={{ width: "100%", height: "60vh" }} />;
};

export default Map;
