/* global google */
import React, { useState, useEffect, useContext } from "react";
import algoliasearch from "algoliasearch/lite";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, GeoPoint } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { geohashForLocation } from "geofire-common";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { geocodeByPlaceId } from "react-places-autocomplete";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "../../Components/items/newItem.css";

function NewTraveller(props) {
  const { user, onClose, addNewItem, fetchData, setRefresh, journey } = props;
  const { control } = useForm();
  const [category, setCategory] = useState("");
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [travellers, setTravellers] = useState([]);

  // Initialize Algolia
  const client = algoliasearch(
    "1REB6WCMBC",
    "f95d9e3225c839f9b5fff98b732406db"
  );
  const index = client.initIndex("Users");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSearch = async (event, newValue) => {
    const results = await index.search(newValue);
    setUserSearchResults(results.hits);
  };

  // Add a new function to handle the selection of a username
  const handleSelectUsername = (event, newValue) => {
    const userName = newValue.userName;
    setSelectedUsernames((prevUsernames) => [...prevUsernames, userName]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Add the selected usernames to the travellers array
    const newTravellers = selectedUsernames.map((username) => {
      // Find the user object that corresponds to the username
      const user = userSearchResults.find((user) => user.userName === username);
      return {
        uid: user.objectID, // replace with the correct property for the user's ID
        userName: user.userName,
      };
    });

    setTravellers((prevTravellers) => [...prevTravellers, ...newTravellers]);

    // Get a reference to the journey document
    const journeyRef = doc(db, "Journeys", journey.id);

    try {
      // Update the journey document with the new travellers array
      await updateDoc(journeyRef, {
        travellers: newTravellers,
      });

      // Display a confirmation alert
      Swal.fire(
        "Success!",
        "The traveller has been added successfully.",
        "success"
      );

      // Close the modal
      onClose();
    } catch (error) {
      // Display an error alert
      Swal.fire(
        "Error!",
        "There was an error adding the traveller. Please try again.",
        "error"
      );
    }
  };

  return (
    <form className="form-container" onSubmit={handleFormSubmit}>
      <div className="form-container">
        <div className="Itemform">
          <div className="formHeading">
            <h1 className="headingdark">Add a new </h1>
            <h1 className="headingLight">Traveller</h1>
          </div>
          <Grid
            className="formControl"
            container
            direction="column"
            spacing={2}
          >
            {" "}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                style={{
                  display: "flex",
                  marginLeft: "30px",
                  justifyContent: "center",
                }}
              >
                <Autocomplete
                  options={userSearchResults}
                  getOptionLabel={(option) => option.userName}
                  onInputChange={handleSearch}
                  onChange={handleSelectUsername}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search users"
                      InputProps={{
                        ...params.InputProps,
                        className: "common-input",
                      }}
                      sx={{ width: 300 }}
                    />
                  )}
                />
              </FormControl>
              <Grid item xs={12}>
                <Button className="submitButton" type="submit">
                  Submit
                </Button>
                <Button
                  className="cancelButton"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </form>
  );
}

export default NewTraveller;
